import { useMutation } from "react-query";

import { constants } from "../../constants";
import { supabase } from "../../utils/supabaseClient";

const patchMenuPdfFile = async ({ file }) => {
  try {
    const { data, error } = await supabase.storage
      .from(constants.publicBucket)
      .update("/files/menu-sakura.pdf", file, {
        contentType: "File",
        upsert: true,
      });

    if (error) throw new Error(error.message);
    if (data) {
      const { data } = await supabase.storage
        .from(constants.publicBucket)
        .getPublicUrl("files/menu-sakura.pdf");
      return data;
    }
  } catch (error) {
    console.error(error.error_description || error.message);
  }
};

export default function useMenuPdfFilePatch(setNotification) {
  return useMutation((datas) => patchMenuPdfFile(datas), {
    onSuccess: () => {
      setNotification("Modifié !!");
      document.getElementById("create-course-form").reset();
    },
  });
}
