export const colors = {
  WHITE: "#FFF",
  BLACK: "#000",
  BLUE: {
    main: "#086EC8",
    darkBleu: "#0977D8",
    mediumBleu: "#065498",
    greyBleu: "#E7F3FE",
  },
  NEUTRAL: {
    dark: "#293034",
    darkGrey: "#666666",
    mediumGrey: "#CCCCCC",
    light: "#F7F7F7",
  },
  ACTION: {
    primary: "#086EC8",
    hover: "#0977D8",
    active: "#398EDA",
    light: "#EBF0FD",
  },
  FUNCTIONAL: {
    success: "#2F7C32",
    successLight: "#E1F4E2",
    warning: "#FC821F",
  },
  SECONDARY: "#D13100",
  // RED: { main: "#D13100" },
  ALERT: {
    error: "rgb(250, 234, 229)",
    warning: "rgb(255, 244, 229)",
    info: "rgb(232, 244, 253)",
    success: "rgb(237, 247, 237)",
  },
  RED: { main: "#e23222b7" },
  BROWN: { main: "tan" },
};
