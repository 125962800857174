import React from "react";

import { Box, Button, Container } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useMenuPdfFilePatch } from "../../../hooks";

const FileUploaded = ({ onFileSelectSuccess, onFileSelectError }) => {
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file.name !== "menu-sakura.pdf")
      onFileSelectError({
        error: 'Le nom du fichier doit être "menu-sakura.pdf"',
      });
    else if (file.type !== "application/pdf")
      onFileSelectError({
        error: 'Le type de document doit être un pdf ".pdf"',
      });
    else onFileSelectSuccess(file);
  };

  return (
    <form id="create-course-form">
      <input type="file" onChange={handleFileInput} />
    </form>
  );
};

export default function UpdatePdfFile() {
  const { t } = useTranslation();

  const [file, setFile] = React.useState(null);
  const [notification, setNotification] = React.useState(null);

  const menuPdfPatch = useMenuPdfFilePatch(setNotification);

  const submitForm = () => {
    menuPdfPatch.mutate({ file });
  };

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <FileUploaded
          onFileSelectSuccess={(file) => setFile(file)}
          onFileSelectError={({ error }) => alert(error)}
        />

        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={submitForm}
          sx={{
            color: "black",
            marginTop: "20px",
            marginBottom: "40px",
          }}
        >
          Uploader le nouveau MENU pdf
        </Button>
        {notification && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3>{notification}</h3>
            <a
              href={menuPdfPatch?.data?.publicURL}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: 10 }}
            >
              Voir le fichier pdf
            </a>
            <div
              style={{ marginLeft: 10, backgroundColor: "red", color: "white" }}
              onClick={() => setNotification(null)}
            >
              x
            </div>
          </div>
        )}
      </Box>
    </Container>
  );
}
