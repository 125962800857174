import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  content: {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    padding: "0px 20px 40px 20px",
  },
  textLineBreak: {
    whiteSpace: "pre-line",
  },
});
export default useStyles;
