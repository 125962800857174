import React from "react";

import {
  Card,
  CardContent,
  CardHeader,
  // CardMedia as MuiCardMedia,
  Divider,
  Typography,
} from "@mui/material";

import { ThemeContext } from "../../../contexts";
import MenuCardWrapper from "../../../domains/updateMenu/MenuCardWrapper";
import useStyles from "./useStyles";

export default function SubMenuBeverage({
  beverage,
  isCardActionActive,
  action,
}) {
  const {
    selectedTheme: {
      palette: { mode },
    },
  } = ThemeContext.useContext();

  const isModeLight = mode === "light";
  const classes = useStyles(isModeLight);

  return (
    <React.Fragment>
      <MenuCardWrapper
        isCardActionActive={isCardActionActive}
        classes={{ root: classes.card }}
        action={action}
      >
        <Card classes={{ root: classes.card }}>
          <CardHeader
            title={beverage.name}
            titleTypographyProps={classes.cardHeader}
          />
          <Divider light variant="middle" />
          {/* <MuiCardMedia
					component="img"
					height="194"
					image="//unsplash.it/345?random&gravity=center"
					alt="Paella beverage"
				/> */}
          <CardContent>
            {beverage.comment && (
              <Typography variant="h5" color="text.secondary" paragraph>
                {beverage.comment}
              </Typography>
            )}
            {beverage.description && (
              <Typography variant="body1" color="text.secondary" paragraph>
                {beverage.description}
              </Typography>
            )}
            {beverage?.twenty_three && (
              <>
                <Typography variant="h3" color="text.primary">
                  23cl : {parseFloat(beverage?.twenty_three).toFixed(2)}€
                </Typography>
                <Divider orientation="vertical" />
              </>
            )}
            {beverage?.twenty_five && (
              <>
                <Typography variant="h3" color="text.primary">
                  25cl : {parseFloat(beverage?.twenty_five).toFixed(2)}€
                </Typography>
                <Divider orientation="vertical" />
              </>
            )}
            {beverage?.thirty_three && (
              <>
                <Typography variant="h3" color="text.primary">
                  33cl : {parseFloat(beverage?.thirty_three).toFixed(2)}€
                </Typography>
                <Divider orientation="vertical" />
              </>
            )}
            {beverage?.fifty && (
              <>
                <Typography variant="h3" color="text.primary">
                  50cl : {parseFloat(beverage?.fifty).toFixed(2)}€
                </Typography>
                <Divider orientation="vertical" />
              </>
            )}
            {beverage?.seventy_five && (
              <>
                <Typography variant="h3" color="text.primary">
                  75cl : {parseFloat(beverage?.seventy_five).toFixed(2)}€
                </Typography>
                <Divider orientation="vertical" />
              </>
            )}
            {beverage?.one_hundred && (
              <>
                <Typography variant="h3" color="text.primary">
                  1L : {parseFloat(beverage?.one_hundred).toFixed(2)}€
                </Typography>
                <Divider orientation="vertical" />
              </>
            )}
            {beverage?.other && (
              <Typography variant="h3" color="text.primary">
                autre : {parseFloat(beverage?.other).toFixed(2)}€
              </Typography>
            )}
          </CardContent>
        </Card>
      </MenuCardWrapper>
    </React.Fragment>
  );
}
