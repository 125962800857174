import React from "react";

import { useMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { node, func } from "prop-types";

import theme from "../../designSystem/template/theme";
import { getDesignTokens } from "../../designSystem/template/theme/getDesignTokens";

const ThemeContext = React.createContext({ toggleColorMode: () => {} });

function useContext() {
  return React.useContext(ThemeContext);
}

const Provider = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: light)");

  const [mode, setMode] = React.useState(prefersDarkMode ? "dark" : "light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const selectedTheme = React.useMemo(
    () => createTheme({ ...theme, ...getDesignTokens(mode) }),
    [mode]
  );

  const value = {
    colorMode,
    selectedTheme,
  };

  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={selectedTheme}>{children} </ThemeProvider>
    </ThemeContext.Provider>
  );
};

Provider.propTypes = {
  children: node,
  colorMode: func,
};

const CustomThemeContext = {
  Provider,
  useContext,
};

export default CustomThemeContext;
