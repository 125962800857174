import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const fallbackLng = "fr";
const ns = ["common"];
// const supportedLngs = ["fr", "en"];
const supportedLngs = ["fr"];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (format === "intlDate") {
          return new Intl.DateTimeFormat(lng).format(value);
        }

        return value;
      },
    },
    load: "languageOnly",
    react: {
      useSuspense: false,
    },
    fallbackLng,
    ns,
    supportedLngs,
  });

export default i18n;
