import React from "react";

import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar,
} from "@mui/material";
import { node, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { useMedia } from "../../../hooks";
import { supabase } from "../../../utils/supabaseClient";
import Address from "../../molecule/Address";
// import LanguageFlags from "../../molecule/LanguageFlags";
import PrivateRoutes from "../../molecule/PrivateRoutes";
import ThemeSwitcher from "../../molecule/ThemeSwitcher";
import useStyles from "./useStyles";

export default function PrivateHeader({ logoUri, logoLabel }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { DesktopAndAbove, TabletAndBelow } = useMedia();

  const [status, setStatus] = React.useState(false);

  const handleDrawerOpen = () => {
    setStatus(!status);
  };

  const handleLogout = () => {
    try {
      supabase.auth.signOut();
      navigate("/authadmin");
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <>
      <TabletAndBelow>
        <AppBar position="fixed" color="defaultColor">
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Drawer
          open={status}
          onClose={handleDrawerOpen}
          anchor="left"
          classes={{ paper: classes.drawer }}
        >
          <Box sx={{ height: "104px" }}>
            <Link to="/dashboard" className={classes.logoLink}>
              <img
                src={logoUri}
                alt={logoLabel}
                title={logoLabel}
                className={classes.logoMobile}
              />
            </Link>
          </Box>

          <Divider light />
          <List>
            <PrivateRoutes />
          </List>

          <List sx={{ marginTop: "auto", textAlign: "center" }}>
            <Divider light />
            <Address />
            <Divider light />
            <Box style={{ display: "flex", justifyContent: "center" }}>
              {/* <LanguageFlags /> */}
            </Box>
            {true && <ThemeSwitcher className={classes.themeSwitcherMobile} />}
          </List>
          <Divider light />
          <List>
            <Button
              type="button"
              variant="text"
              color="secondary"
              onClick={handleLogout}
              startIcon={<MeetingRoomIcon />}
              sx={{ marginLeft: "20px" }}
            >
              {t("button.signout")}
            </Button>
          </List>
        </Drawer>
      </TabletAndBelow>

      <DesktopAndAbove>
        <AppBar position="fixed" elevation={1} color="defaultColor">
          <Container maxWidth="xl">
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <Link to="/">
                <img
                  src={logoUri}
                  alt={logoLabel}
                  title={logoLabel}
                  className={classes.logo}
                />
              </Link>
              <Address />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    aligntItems: "center",
                  }}
                >
                  {/* <LanguageFlags /> */}
                  {true && (
                    <ThemeSwitcher className={classes.themeSwitcherMobile} />
                  )}
                  <Button
                    type="button"
                    variant="text"
                    color="secondary"
                    onClick={handleLogout}
                    startIcon={<MeetingRoomIcon />}
                    sx={{ marginLeft: "20px" }}
                  >
                    {t("button.signout")}
                  </Button>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "66px",
                  }}
                >
                  <PrivateRoutes />
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Toolbar
          style={{
            minHeight: "114px",
          }}
        />
      </DesktopAndAbove>
    </>
  );
}

PrivateHeader.propTypes = {
  children: node,
  logoUri: string,
  logoLabel: string,
  right: node,
};
