import { useMutation } from "react-query";

import { supabase } from "../../utils/supabaseClient";

const patchBeverageById = async ({ beverage, beverageInfos, beverageId }) => {
  try {
    const { data, error } = await supabase
      .from("beverage_item")
      .update({
        name: beverageInfos.name !== "" ? beverageInfos.name : beverage.name,
        comment:
          beverageInfos.comment !== ""
            ? beverageInfos.comment
            : beverage.comment,
        description:
          beverageInfos.description !== ""
            ? beverageInfos.description
            : beverage.description,
        other:
          beverageInfos.other !== "" ? beverageInfos.other : beverage.other,
        twenty_three:
          beverageInfos.twenty_three !== ""
            ? beverageInfos.twenty_three
            : beverage.twenty_three,
        twenty_five:
          beverageInfos.twenty_five !== ""
            ? beverageInfos.twenty_five
            : beverage.twenty_five,
        thirty_three:
          beverageInfos.thirty_three !== ""
            ? beverageInfos.thirty_three
            : beverage.thirty_three,
        fifty:
          beverageInfos.fifty !== "" ? beverageInfos.fifty : beverage.fifty,
        seventy_five:
          beverageInfos.seventy_five !== ""
            ? beverageInfos.seventy_five
            : beverage.seventy_five,
        one_hundred:
          beverageInfos.one_hundred !== ""
            ? beverageInfos.one_hundred
            : beverage.one_hundred,
      })
      .match({ id: beverageId });

    if (error) throw new Error(error.message);
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error.error_description || error.message);
  }
};

export default function useBeveragePatch() {
  return useMutation((datas) => patchBeverageById(datas), {
    onSuccess: (data) => {
      return data;
    },
  });
}
