import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  content: {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    padding: 20,
  },
  typeOfDishes: {
    whiteSpace: "pre-line",
  },
  category: {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: 20,
  },
});
export default useStyles;
