import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import { ThemeContext } from "../../../contexts";
import { useMedia } from "../../../hooks";
import Footer from "../../organism/Footer";
import PublicHeader from "../../organism/PublicHeader";
import useStyles from "./useStyles";

const logo = `${process.env.PUBLIC_URL}/static/assets/logo-sakura.png`;
const logoWhite = `${process.env.PUBLIC_URL}/static/assets/logo-sakura-white.png`;

export default function PublicTemplate() {
  const {
    selectedTheme: {
      palette: { mode },
    },
  } = ThemeContext.useContext();
  const { DesktopAndAbove } = useMedia();

  const isModeLight = mode === "light";
  const classes = useStyles();

  return (
    <>
      <PublicHeader
        logoUri={isModeLight ? logo : logoWhite}
        logoLabel="Sakura restaurant Logo"
      />
      <Box className={classes.mainContent}>
        <Outlet />
      </Box>
      <DesktopAndAbove>
        <Footer />
      </DesktopAndAbove>
    </>
  );
}
