import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Box, IconButton } from "@mui/material";

import { ThemeContext } from "../../../contexts";

export default function ThemeSwitcher() {
  const { colorMode, selectedTheme } = ThemeContext.useContext();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 1,
        paddingTop: "5px",
        paddingBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <IconButton
        sx={{ ml: 1 }}
        onClick={colorMode.toggleColorMode}
        color="inherit"
      >
        {selectedTheme.palette.mode === "dark" ? (
          <Brightness7Icon />
        ) : (
          <Brightness4Icon />
        )}
      </IconButton>
    </Box>
  );
}
