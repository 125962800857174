import React from "react";

import {
  Box,
  Button,
  Container,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";

import SubMenuBeverage from "../../../designSystem/organism/SubMenuBeverage";
import useBeveragePost from "../../../hooks/useBeveragePost";
import useStyles from "./useStyles";

export default function NewBeverage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const classes = useStyles();

  const [categoryId] = useLocalStorage("sub-category-menu", null);

  const [newBeverage, setNewBeverage] = React.useState({
    name: "",
    comment: null,
    description: null,
    other: null,
    twenty_three: null,
    twenty_five: null,
    thirty_three: null,
    fifty: null,
    seventy_five: null,
    one_hundred: null,
  });

  const [validationErrors, setValidationErrors] = React.useState({});
  const postNewBeverage = useBeveragePost();

  React.useEffect(() => {
    let validationErrors = {};
    if (newBeverage) {
      const hasNameError =
        (!newBeverage.name || newBeverage.name.startsWith(" ")) ?? true;
      const hasOtherPriceError = isNaN(newBeverage.other) ?? true;
      const hasTwentyThreePriceError = isNaN(newBeverage.twenty_three) ?? true;
      const hasTwentyFivePriceError = isNaN(newBeverage.twenty_five) ?? true;
      const hasThirtyThreePriceError = isNaN(newBeverage.thirty_three) ?? true;
      const hasFiftyPriceError = isNaN(newBeverage.fifty) ?? true;
      const hasSeventyFivePriceError = isNaN(newBeverage.seventy_five) ?? true;
      const hasOneHundredPriceError = isNaN(newBeverage.one_hundred) ?? true;

      validationErrors = {
        ...validationErrors,
        name: hasNameError,
        other: hasOtherPriceError,
        twenty_three: hasTwentyThreePriceError,
        twenty_five: hasTwentyFivePriceError,
        thirty_three: hasThirtyThreePriceError,
        fifty: hasFiftyPriceError,
        seventy_five: hasSeventyFivePriceError,
        one_hundred: hasOneHundredPriceError,
      };
    }

    setValidationErrors(validationErrors);
  }, [newBeverage]);

  React.useEffect(() => {
    if (postNewBeverage.data) {
      navigate("/update-menu");
    }
  }, [navigate, postNewBeverage.data]);

  return (
    <>
      <MetaTags>
        <title>{t("metas.updateMenuPageTitle")}</title>
        <meta name="title" content={t("metas.updateMenuPageTitle")} />
        <meta
          name="description"
          content={t("metas.updateMenuPageDescription")}
        />
      </MetaTags>

      <Box className={classes.content}>
        <Container maxWidth="xl" sx={{ margin: "Opx auto 40px auto" }}>
          <Button
            variant="outlined"
            onClick={() => navigate("/update-menu")}
            sx={{ marginTop: "30px", marginBottom: "40px" }}
          >
            Retour
          </Button>

          <Divider variant="middle" />

          <Typography
            variant="h2"
            component="h2"
            sx={{ marginBottom: "10px", fontWeight: "bold" }}
          >
            Nouvelle boisson dans {location.state.categoryLabel} /{" "}
            {location.state.subCategory}:
          </Typography>
          <Container
            maxWidth="lg"
            sx={{
              paddingTop: "30px",
              paddingBottom: "40px",
              textAlign: "left",
            }}
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6} xl={4}>
                {newBeverage && (
                  <Box>
                    <SubMenuBeverage beverage={newBeverage} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Container>

          <Divider />
          <Container maxWidth="lg" sx={{ marginTop: "40px" }}>
            {newBeverage && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  error={!!validationErrors.name}
                  placeholder="Nom"
                  label="Nom du plat"
                  type="text"
                  variant="outlined"
                  value={newBeverage.name}
                  onChange={(e) => {
                    setNewBeverage({
                      ...newBeverage,
                      name: e.target.value.toUpperCase(),
                    });
                  }}
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                {!!validationErrors.name && (
                  <FormHelperText id="name-error" sx={{ color: "red" }}>
                    Il manque le nom de la boisson
                  </FormHelperText>
                )}
                <TextField
                  placeholder="Comment"
                  label="Comment"
                  type="text"
                  variant="outlined"
                  value={newBeverage.comment}
                  onChange={(e) =>
                    setNewBeverage({
                      ...newBeverage,
                      comment: e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />

                <TextField
                  placeholder="Description"
                  label="Description"
                  type="text"
                  variant="outlined"
                  value={newBeverage.description}
                  multiline
                  rows={4}
                  onChange={(e) =>
                    setNewBeverage({
                      ...newBeverage,
                      description:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />

                <TextField
                  error={!!validationErrors.other}
                  placeholder="Autre quantité"
                  label="Autre quantité : préciser en commentaire la quantité"
                  type="text"
                  variant="outlined"
                  value={newBeverage.other}
                  onChange={(e) =>
                    setNewBeverage({
                      ...newBeverage,
                      other: e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />

                <TextField
                  error={!!validationErrors.twenty_three}
                  placeholder="23 cl, ex : 15.90"
                  label="23 cl"
                  type="text"
                  variant="outlined"
                  value={newBeverage.twenty_three}
                  onChange={(e) =>
                    setNewBeverage({
                      ...newBeverage,
                      twenty_three:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <TextField
                  error={!!validationErrors.twenty_five}
                  placeholder="25 cl, ex : 15.90"
                  label="25 cl"
                  type="text"
                  variant="outlined"
                  value={newBeverage.twenty_five}
                  onChange={(e) =>
                    setNewBeverage({
                      ...newBeverage,
                      twenty_five:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <TextField
                  error={!!validationErrors.thirty_three}
                  placeholder="33 cl, ex : 15.90"
                  label="33 cl"
                  type="text"
                  variant="outlined"
                  value={newBeverage.thirty_three}
                  onChange={(e) =>
                    setNewBeverage({
                      ...newBeverage,
                      thirty_three:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <TextField
                  error={!!validationErrors.fifty}
                  placeholder="50 cl, ex : 15.90"
                  label="50 cl"
                  type="text"
                  variant="outlined"
                  value={newBeverage.fifty}
                  onChange={(e) =>
                    setNewBeverage({
                      ...newBeverage,
                      fifty: e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />

                <TextField
                  error={!!validationErrors.seventy_five}
                  placeholder="75 cl, ex : 15.90"
                  label="75 cl"
                  type="text"
                  variant="outlined"
                  value={newBeverage.seventy_five}
                  onChange={(e) =>
                    setNewBeverage({
                      ...newBeverage,
                      seventy_five:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />

                <TextField
                  error={!!validationErrors.one_hundred}
                  placeholder="1 litre, ex : 15.90"
                  label="1 litre"
                  type="text"
                  variant="outlined"
                  value={newBeverage.one_hundred}
                  onChange={(e) =>
                    setNewBeverage({
                      ...newBeverage,
                      one_hundred:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />

                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    postNewBeverage.mutate({
                      newBeverage,
                      categoryId,
                    });
                  }}
                  sx={{ color: "black" }}
                  disabled={Object.values(validationErrors).some(
                    (val) => val === true
                  )}
                >
                  Ajouter la boisson
                </Button>
              </Box>
            )}
          </Container>
        </Container>
      </Box>
    </>
  );
}
