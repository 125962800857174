import { makeStyles } from "@mui/styles";

import { colors } from "../../template/theme/colors";

const useStyles = makeStyles((isModeLight) => ({
  card: {
    padding: "20px 0px",
    height: "100%",
    textAlign: "left",
    backgroundColor: isModeLight ? colors.NEUTRAL.light : colors.NEUTRAL.dark,
  },
  cardHeader: {
    align: "left",
  },
}));
export default useStyles;
