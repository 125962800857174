import { useQuery } from "react-query";

import { supabase } from "../../utils/supabaseClient";

const getMenu = async ({ id }) => {
  const { data, error } = await supabase
    .from("menu_item")
    .select("*")
    .filter("id", "eq", id);

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Menu not found");
  }
  return data;
};

export default function useMenu(id) {
  return useQuery(["menu", id], () => getMenu({ id }), {
    refetchOnMount: false,
  });
}
