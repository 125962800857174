import React from "react";

import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import { ThemeContext } from "../../../contexts";
import RequireAuth from "../../../helpers/RequireAuth";
import PrivateHeader from "../../organism/PrivateHeader";
import useStyles from "./useStyles";

const logo = `${process.env.PUBLIC_URL}/static/assets/logo-sakura.png`;
const logoWhite = `${process.env.PUBLIC_URL}/static/assets/logo-sakura-white.png`;

export default function PrivateTemplate() {
  const {
    selectedTheme: {
      palette: { mode },
    },
  } = ThemeContext.useContext();

  const isModeLight = mode === "light";
  const classes = useStyles();

  return (
    <RequireAuth>
      <PrivateHeader
        logoUri={isModeLight ? logo : logoWhite}
        logoLabel="Sakura restaurant Logo"
      />
      <Box className={classes.mainContent}>
        <Outlet />
      </Box>
    </RequireAuth>
  );
}
