import React from "react";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  // CardMedia as MuiCardMedia,
  Divider,
  Typography,
} from "@mui/material";

import { ThemeContext } from "../../../contexts";
import MenuCardWrapper from "../../../domains/updateMenu/MenuCardWrapper";
import { Allergy, Leaf } from "../../icons";
import useStyles from "./useStyles";

export default function SubMenuDish({ dish, isCardActionActive, action }) {
  const {
    selectedTheme: {
      palette: { mode },
    },
  } = ThemeContext.useContext();

  const isModeLight = mode === "light";
  const classes = useStyles(isModeLight);
  const vegetarianPictoStyle = {
    color: isModeLight ? "seagreen" : "yellowgreen",
    marginRight: "10px",
  };
  const allergyPictoStyle = {
    color: isModeLight ? "mediumvioletred" : "palevioletred",
    marginRight: "10px",
  };

  return (
    <React.Fragment>
      <MenuCardWrapper
        isCardActionActive={isCardActionActive}
        classes={{ root: classes.card }}
        action={action}
      >
        <Card classes={{ root: classes.card }}>
          <CardHeader
            title={dish.name}
            titleTypographyProps={classes.cardHeader}
            action={
              <Typography variant="h3" color="text.primary">
                {parseFloat(dish.price).toFixed(2)}€
              </Typography>
            }
          />
          <Divider light variant="middle" />
          {/* <MuiCardMedia component="img" height="194" image="//unsplash.it/345?random&gravity=center" alt="Paella dish" /> */}
          <CardContent>
            <Typography variant="h5" color="text.secondary" paragraph>
              {dish.comment}
            </Typography>
            <Typography variant="h6" color="text.secondary" paragraph>
              {dish.description}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {dish.is_vegetarian === true && (
                <Box className={classes.vegetarianContainer}>
                  <Leaf sx={vegetarianPictoStyle} />
                  <Typography variant="body1" color="text.secondary">
                    végétarien
                  </Typography>
                </Box>
              )}
            </Typography>
            {dish.allergy && (
              <>
                <Box className={classes.vegetarianContainer}>
                  <Allergy sx={allergyPictoStyle} />
                  <Typography variant="body1" color="text.secondary">
                    Allergies (liste non exhaustive) :
                  </Typography>
                </Box>
                <Typography variant="body1" color="text.secondary">
                  {dish.allergy}
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
      </MenuCardWrapper>
    </React.Fragment>
  );
}
