import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";

import { useAuth } from "../../hooks";
import useStyles from "./useStyles";

export default function Dashboard() {
  const { t } = useTranslation();
  const classes = useStyles();

  const { user } = useAuth();

  return (
    <>
      <MetaTags>
        <title>{t("metas.dashboardPageTitle")}</title>
        <meta name="title" content={t("metas.dashboardPageTitle")} />
        <meta name="description" content={t("metas.contactPageDescription")} />
      </MetaTags>
      <Box className={classes.content}>
        <Container maxWidth="xl" sx={{ margin: "Opx auto 40px auto" }}>
          <Typography variant="h3" component="h3">
            Dashboard
          </Typography>
          <Typography variant="body1" component="p">
            Bonjour {user?.email}
          </Typography>
        </Container>
      </Box>
    </>
  );
}
