import { useMediaQuery } from "react-responsive";

export default function useMedia() {
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 });
    return isNotMobile ? children : null;
  };

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({
      minWidth: 900,
    });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({
      minWidth: 600,
      maxWidth: 899,
    });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({
      maxWidth: 599,
    });
    return isMobile ? children : null;
  };

  const useDesktopMediaQuery = () =>
    useMediaQuery({
      query: `(min-width: 900px)`,
    });

  const useTabletAndBelowMediaQuery = () =>
    useMediaQuery({
      query: `(max-width: 899px)`,
    });

  const DesktopAndAbove = ({ children }) => {
    const isDesktop = useDesktopMediaQuery();

    return isDesktop ? children : null;
  };

  const TabletAndBelow = ({ children }) => {
    const isTabletAndBelow = useTabletAndBelowMediaQuery();

    return isTabletAndBelow ? children : null;
  };

  const isDesktopAndAboveMediaQuery = useMediaQuery({ minWidth: 900 });
  const isTabletAndBelowMediaQuery = useMediaQuery({ minWidth: 899 });
  return {
    Default,
    Desktop,
    Tablet,
    Mobile,
    DesktopAndAbove,
    TabletAndBelow,
    isDesktopAndAboveMediaQuery,
    isTabletAndBelowMediaQuery,
    useTabletAndBelowMediaQuery,
  };
}
