import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  mainContent: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "space-around",
    minHeight: "100vh",
    paddingBottom: 40,
  },
});
export default useStyles;
