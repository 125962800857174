import React from "react";

import { Box, Container, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import Slider from "react-slick";

import { colors } from "../../../designSystem/template/theme/colors";
import useStyles from "./useStyles";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: true,
  lazyLoad: true,
  className: "center",
  arrows: false,
};
const homePhoto01 = `${process.env.PUBLIC_URL}/static/assets/home/1.jpg`;
const homePhoto02 = `${process.env.PUBLIC_URL}/static/assets/home/2.jpg`;
const homePhoto03 = `${process.env.PUBLIC_URL}/static/assets/home/3.jpg`;
const homePhoto04 = `${process.env.PUBLIC_URL}/static/assets/home/4.jpg`;
const homePhoto05 = `${process.env.PUBLIC_URL}/static/assets/home/5.jpg`;
const homePhoto06 = `${process.env.PUBLIC_URL}/static/assets/home/6.jpg`;
const homePhoto07 = `${process.env.PUBLIC_URL}/static/assets/home/7.jpg`;
const chefPhoto = `${process.env.PUBLIC_URL}/static/assets/chef.jpg`;

export default function HomePage() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <MetaTags>
        <title>{t("metas.homePageTitle")}</title>
        <meta name="title" content={t("metas.homePageTitle")} />
        <meta name="description" content={t("metas.homePageDescription")} />
      </MetaTags>
      <Container
        maxWidth="xl"
        disableGutters
        sx={{ margin: "Opx auto 40px auto" }}
      >
        <Slider {...settings} style={{ height: "auto", marginBottom: "60px" }}>
          <img
            src={homePhoto01}
            alt="Intérieur du restaurant, vue sur les tables"
          />
          <img
            src={homePhoto02}
            alt="Intérieur du restaurant, vue sur le comptoir"
          />
          <img
            src={homePhoto03}
            alt="Prise de vue sur le chef souriant derrière le comptoir"
          />
          <img
            src={homePhoto04}
            alt="Prise de vue sur le chef en pleine préparation d'un plat"
          />
          <img
            src={homePhoto05}
            alt="Vue sur un objet de décoration japonais"
          />
          <img
            src={homePhoto06}
            alt="Prise de vue sur la façade de l'enseigne et du restaurant"
          />
          <img
            src={homePhoto07}
            alt="Prise de vue sur la façade de l'enseigne et du restaurant de nuit"
          />
        </Slider>
      </Container>
      <Box className={classes.content}>
        <Container
          maxWidth="md"
          sx={{ textAlign: "center", marginBottom: "30px" }}
        >
          <Typography component="h1" variant="h3" paragraph>
            {t("heading.welcome").toUpperCase()}
          </Typography>
          <Typography component="p" variant="body1" paragraph>
            {t("homepage.welcome01")}
          </Typography>
          <Typography component="p" variant="body1" paragraph>
            {t("homepage.welcome02")}
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            {t("homepage.welcome03")}
          </Typography>

          <Divider
            sx={{
              marginTop: "40px",
              marginBottom: "40px",
              "&::before, &::after": {
                borderColor: colors.BROWN.main,
              },
            }}
          >
            <img
              src={chefPhoto}
              alt="Le chef du restaurant souriant."
              className={classes.chiefPicture}
            />
          </Divider>

          <Typography component="h2" variant="h3" paragraph>
            {t("heading.chief").toUpperCase()}
          </Typography>
          <Typography component="p" variant="body1" paragraph>
            {t("homepage.chief01")}
          </Typography>
          <Typography component="p" variant="body1" paragraph>
            {t("homepage.chief02")}
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            {t("homepage.chief03")}
          </Typography>
        </Container>
      </Box>
    </>
  );
}
