import { makeStyles } from "@mui/styles";

import { colors } from "../../../designSystem/template/theme/colors";

const useStyles = makeStyles({
  content: {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    padding: "0px 20px 20px 20px",
  },
  chiefPicture: {
    overflow: "hidden",
    WebkitBorderRadius: "50%",
    MozBorderRadius: "50%",
    borderRadius: "50%",
    width: "165px",
    height: "165px",
    border: `1px solid ${colors.BROWN.main}`,
    margin: "auto",
  },
});
export default useStyles;
