import { useQuery } from "react-query";

import { supabase } from "../../utils/supabaseClient";

const getBeverage = async ({ id }) => {
  const { data, error } = await supabase
    .from("beverage_item")
    .select("*")
    .filter("id", "eq", id);

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Beverage not found");
  }
  return data;
};

export default function useBeverage(id) {
  return useQuery(["beverage", id], () => getBeverage({ id }), {
    refetchOnMount: false,
  });
}
