import React from "react";

import { Box, Container, Typography } from "@mui/material";

import { ThemeContext } from "../../../contexts";
import { useMedia } from "../../../hooks";
import { colors } from "../../template/theme/colors";
import useStyles from "./useStyles";

export default function Footer() {
  const {
    selectedTheme: {
      palette: { mode },
    },
  } = ThemeContext.useContext();

  const isModeLight = mode === "light";
  const classes = useStyles(isModeLight);
  const { DesktopAndAbove, TabletAndBelow } = useMedia();

  return (
    <>
      <TabletAndBelow>
        <Typography
          variant="body2"
          component="p"
          className={classes.mobileCopyright}
        >
          © 2022 - Restaurant Sakura | 16 bis, rue Thiers - 59000 Lille | design
          by Restaurant Sakura
        </Typography>
      </TabletAndBelow>

      <DesktopAndAbove>
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: isModeLight ? "tan" : colors.NEUTRAL.dark,
            opacity: 0.95,
            position: "fixed",
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
          }}
        >
          <Container maxWidth="xl">
            <Typography
              variant="body2"
              component="p"
              className={classes.copyright}
            >
              © 2022 - Restaurant Sakura | 16 bis, rue Thiers - 59000 Lille |
              design by Restaurant Sakura
            </Typography>
          </Container>
        </Box>
      </DesktopAndAbove>
    </>
  );
}
