import { useQuery } from "react-query";

import { supabase } from "../../utils/supabaseClient";

const getAnnouncement = async () => {
  const { data, error } = await supabase
    .from("contact")
    .select("*")
    .match({ id: 1 });

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Accouncement not found");
  }
  return data;
};

export default function useAnnouncement() {
  return useQuery(["announcement"], () => getAnnouncement(), {});
}
