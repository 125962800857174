import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  selectedText: {
    fontWeight: "600 !important",
    width: "max-content !important",
    backgroundColor: "rgb(255 255 255 / 0%) !important",
    textDecoration: "underline !important",
    textUnderlineOffset: "6px !important",
    fontSize: "18px !important",
  },
  navLabel: {
    width: "max-content !important",
    backgroundColor: "rgba(255 255 255 / 0%) !important",
  },
  navButton: {
    backgroundColor: "rgb(255 255 255 / 0%) !important",
    "&:hover": {
      backgroundColor: "rgba(255 255 255 / 0%) !important",
    },
  },
});

export default useStyles;
