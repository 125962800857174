import { useMutation } from "react-query";

import { supabase } from "../../utils/supabaseClient";

const deleteMenu = async ({ menuId }) => {
  try {
    const { data, error } = await supabase
      .from("menu_item")
      .delete()
      .match({ id: menuId });
    if (error) throw new Error(error.message);
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error.error_description || error.message);
  }
};

export default function useMenuDelete() {
  return useMutation((datas) => deleteMenu(datas), {
    onSuccess: (data) => {
      return data;
    },
  });
}
