import React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import { equals } from "ramda";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useNavigate, useParams } from "react-router-dom";

import SubMenuDish from "../../../designSystem/organism/SubMenuDish";
import { colors } from "../../../designSystem/template/theme/colors";
import { useMenu, useMenuDelete, useMenuPatch } from "../../../hooks";
import useStyles from "./useStyles";

const vegetarianOptions = [
  {
    value: false,
    label: "NON",
  },
  {
    value: true,
    label: "OUI",
  },
];
export default function Menu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();

  const { isLoading, data: fetchedMenu, refetch } = useMenu(params?.id);
  const menu = fetchedMenu?.[0];
  const menuId = menu?.id;

  const [dishInfos, setDishInfos] = React.useState(menu);
  const [validationErrors, setValidationErrors] = React.useState(menu);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteSecurity, setDeleteSecurity] = React.useState(null);

  const patchMenu = useMenuPatch();
  const deleteMenu = useMenuDelete();

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  React.useEffect(() => {
    setDishInfos(menu);
  }, [menu]);

  React.useEffect(() => {
    if (patchMenu.data) refetch();
  }, [patchMenu.data, refetch]);

  React.useEffect(() => {
    if (deleteMenu.data) navigate("/update-menu");
  }, [deleteMenu.data, navigate]);

  React.useEffect(() => {
    let validationErrors = {};
    if (dishInfos) {
      const hasNameError =
        (!dishInfos.name || dishInfos.name.startsWith(" ")) ?? true;
      const hasDescriptionError =
        (!dishInfos.description ||
          dishInfos.description.startsWith(" ") ||
          dishInfos.description.length <= 1) ??
        true;
      const hasPriceError = isNaN(dishInfos.price) ?? true;
      const hasAllergyError =
        ((!dishInfos.allergy ||
          dishInfos.allergy.startsWith(" ") ||
          dishInfos.allergy.length === 0) &&
          dishInfos.allergy !== null) ??
        true;

      validationErrors = {
        ...validationErrors,
        name: hasNameError,
        description: hasDescriptionError,
        price: hasPriceError,
        allergy: hasAllergyError,
      };
    }
    setValidationErrors(validationErrors);
  }, [dishInfos]);

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <MetaTags>
        <title>{t("metas.updateMenuPageTitle")}</title>
        <meta name="title" content={t("metas.updateMenuPageTitle")} />
        <meta
          name="description"
          content={t("metas.updateMenuPageDescription")}
        />
      </MetaTags>

      <Box className={classes.content}>
        <Container maxWidth="xl" sx={{ margin: "Opx auto 40px auto" }}>
          <Button
            variant="outlined"
            onClick={() => navigate("/update-menu")}
            sx={{ marginTop: "30px", marginBottom: "40px" }}
          >
            Retour
          </Button>

          <Divider variant="middle" />
          <Button
            type="button"
            color="error"
            variant="contained"
            onClick={handleOpen}
            sx={{ margin: "30px 30px 30px 30px" }}
          >
            SUPPRIMER LE PLAT DU MENU...
          </Button>
          <Divider variant="middle" />

          <Container
            maxWidth="lg"
            sx={{
              paddingTop: "30px",
              paddingBottom: "40px",
              textAlign: "left",
            }}
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6} xl={4}>
                <Box>
                  <Typography
                    variant="h2"
                    component="h2"
                    sx={{ marginBottom: "10px" }}
                  >
                    Actuel :
                  </Typography>
                  <SubMenuDish dish={menu} />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} xl={4}>
                {dishInfos && (
                  <Box>
                    <Typography
                      variant="h2"
                      component="h2"
                      sx={{ marginBottom: "10px", fontWeight: "bold" }}
                    >
                      Nouveau :
                    </Typography>
                    <SubMenuDish dish={dishInfos} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Container>

          <Divider />
          <Container maxWidth="lg" sx={{ marginTop: "40px" }}>
            {dishInfos && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  error={!!validationErrors.name}
                  placeholder="Nom"
                  label="Nom du plat"
                  type="text"
                  variant="outlined"
                  value={dishInfos.name}
                  onChange={(e) => {
                    setDishInfos({
                      ...dishInfos,
                      name: e.target.value.toUpperCase(),
                    });
                  }}
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                {!!validationErrors.name && (
                  <FormHelperText id="name-error" sx={{ color: "red" }}>
                    Il manque le nom du plat
                  </FormHelperText>
                )}
                <TextField
                  placeholder="Comment"
                  label="Comment"
                  type="text"
                  variant="outlined"
                  value={dishInfos.comment}
                  onChange={(e) =>
                    setDishInfos({
                      ...dishInfos,
                      comment: e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <TextField
                  error={!!validationErrors.description}
                  placeholder="Description"
                  label="Description"
                  type="text"
                  variant="outlined"
                  value={dishInfos.description}
                  multiline
                  rows={4}
                  onChange={(e) =>
                    setDishInfos({ ...dishInfos, description: e.target.value })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                {!!validationErrors.description && (
                  <FormHelperText id="name-error" sx={{ color: "red" }}>
                    Il faut ajouter une description du plat
                  </FormHelperText>
                )}
                <TextField
                  error={!!validationErrors.price}
                  placeholder="Prix avec un point (pas de virgule), ex : 15.90"
                  label="Prix avec un point (pas de virgule)"
                  type="text"
                  variant="outlined"
                  value={dishInfos.price}
                  onChange={(e) =>
                    setDishInfos({ ...dishInfos, price: e.target.value })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <TextField
                  placeholder="Végétarien : oui / non"
                  label="Végétarien"
                  select
                  value={dishInfos?.is_vegetarian || false}
                  onChange={(e) =>
                    setDishInfos({
                      ...dishInfos,
                      is_vegetarian: e.target.value === "true",
                    })
                  }
                  SelectProps={{
                    native: true,
                  }}
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  {vegetarianOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
                <TextField
                  error={!!validationErrors.allergy}
                  placeholder="Liste d'allergies"
                  label="Allergènes"
                  type="text"
                  variant="outlined"
                  value={dishInfos.allergy}
                  onChange={(e) =>
                    setDishInfos({
                      ...dishInfos,
                      allergy: e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    patchMenu.mutate({
                      menu,
                      dishInfos,
                      menuId,
                    });
                  }}
                  sx={{ color: "black" }}
                  disabled={
                    Object.values(validationErrors).some(
                      (val) => val === true
                    ) || equals(dishInfos, menu)
                  }
                >
                  Modifier le plat
                </Button>
              </Box>
            )}
          </Container>
        </Container>
        <Modal open={openModal} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2">
              Vous êtes sur le point de supprimer le plat {menu.name}
            </Typography>
            <Typography sx={{ mt: 2, mb: 2 }}>
              Si vous le supprimez, il n'y aura pas de retour possible.
            </Typography>
            <Divider />
            <Typography sx={{ mt: 2 }}>
              Pour démarrer la suppression, taper :{" "}
            </Typography>
            <Typography sx={{ mb: 2, color: colors.SECONDARY }}>
              {menu.name}
            </Typography>
            <TextField
              fullWidth
              placeholder="Sécurité"
              label="Sécurité"
              type="text"
              variant="outlined"
              value={deleteSecurity}
              onChange={(e) => setDeleteSecurity(e.target.value)}
              sx={{ marginTop: "10px", marginBottom: "10px" }}
            />

            <Divider />

            <Typography sx={{ mt: 2 }}>
              En appuyant sur le bouton suivant, le plat va être supprimer.
            </Typography>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                deleteMenu.mutate({
                  menuId,
                });
              }}
              sx={{ color: "black" }}
              disabled={deleteSecurity !== menu.name}
            >
              Supprimer le plat définitivement
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
