import React from "react";

import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAnnouncementPatch, useAnnouncement } from "../../../hooks";

export default function UpdateInfosMessage() {
  const { t } = useTranslation();

  const { isLoading: isAnnouncementLoading, data: announcement } =
    useAnnouncement();
  const announcementPatch = useAnnouncementPatch();

  const [message, setMessage] = React.useState("");

  return (
    <Container maxWidth="xl">
      <Typography variant="h2" color="text.primary" paragraph>
        Message de base :{" "}
      </Typography>

      <Typography variant="p" color="text.primary" paragraph>
        Toutes informations particulières vous sera annoncées par le biais de
        cette zone.
      </Typography>

      <Typography variant="h2" color="text.primary" paragraph>
        Message actuel :
      </Typography>
      {!isAnnouncementLoading && (
        <Typography
          variant="p"
          color="text.primary"
          paragraph
          sx={{ whiteSpace: "pre-line" }}
        >
          {announcement[0]?.info}
        </Typography>
      )}

      <Typography variant="h3" color="text.primary" paragraph>
        Nouveau message :
      </Typography>
      <Typography
        component="p"
        variant="subtitle1"
        paragraph
        sx={{ whiteSpace: "pre-line" }}
      >
        {message}
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          placeholder="Message"
          label="Message"
          type="text"
          variant="outlined"
          multiline
          fullwidth
          rows={6}
          onChange={(e) => setMessage(e.target.value)}
          sx={{ marginTop: "10px", marginBottom: "10px" }}
        />
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            announcementPatch.mutate({
              message,
            });
          }}
          sx={{
            color: "black",
            marginTop: "20px",
            marginBottom: "40px",
          }}
        >
          Modifier le message d'information
        </Button>
      </Box>
    </Container>
  );
}
