import { createTheme } from "@mui/material/styles";

import { colors } from "./colors";
import { themeConstants } from "./themeConstants";

const fontFamilyHind = "hind";
const fontFamilyMontserrat = "montserrat";

export default createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: themeConstants.breakpoints.values,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          scrollbarColor: "#d6d6d6c6",
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#ffffff",
            width: "8px",
            height: "4px",
            borderRadius: 8,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            border: "3px solid #c9c9c9",
            backgroundColor: "#c9c9c9",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              border: "3px solid #c2c2c2",
              backgroundColor: "#c2c2c2",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              boxShadow: "5px 0 20px #000",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              border: "3px solid #757575",
              backgroundColor: "#757575",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            borderRadius: 8,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
          },
        },
      },
    },
    MuiAppBar: {
      root: {},
      colorPrimary: {
        backgroundColor: colors.WHITE,
      },
    },
    MuiButton: {
      root: {
        padding: "9px 18px",
        lineHeight: "1.1875rem",
      },
      label: {
        fontFamily: `${fontFamilyHind} , 'Regular'`,
        lineHeight: "22px",
        textTransform: "initial",
        fontSize: 14,
        [`@media (max-width:${themeConstants.breakpoints.values.sm}px)`]: {
          lineHeight: "26px",
          fontSize: 16,
        },
      },
      endIcon: {
        marginLeft: 0,
        marginRight: 0,
      },
      iconSizeSmall: {
        "& > *:first-child": {
          fontSize: 18,
        },
      },
      iconSizeMedium: {
        "& > *:first-child": {
          fontSize: 18,
        },
      },
      iconSizeLarge: {
        "& > *:first-child": {
          fontSize: 18,
        },
      },
    },
    MuiDrawer: {
      paper: {
        padding: 12,
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: colors.NEUTRAL.light,
          borderLeft: "solid",
          borderLeftWidth: 4,
          borderLeftColor: colors.SECONDARY,
        },
      },
      gutters: {
        justifyContent: "center",
        paddingLeft: 20,
        paddingRight: 15,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "none",
        marginRight: 10,
      },
    },
  },
  palette: {
    background: {
      default: colors.WHITE,
    },
    primary: {
      main: colors.BLUE.main,
    },
    secondary: {
      main: colors.SECONDARY,
    },
    error: {
      main: colors.SECONDARY,
    },
    textSecondary: {
      main: colors.NEUTRAL.darkGrey,
    },
    neutral: {
      main: colors.NEUTRAL.darkGrey,
    },
    red: {
      main: colors.RED.main,
    },
    success: {
      main: colors.FUNCTIONAL.success,
    },
  },
  typography: {
    fontFamily: fontFamilyHind,
    h1: {
      fontFamily: fontFamilyMontserrat,
      fontSize: 32,
      fontWeight: 700,
      lineHeight: "39px",
      [`@media (max-width:${themeConstants.breakpoints.values.sm}px)`]: {
        fontSize: 24,
        lineHeight: "29px",
      },
    },
    h2: {
      fontSize: 24,
      lineHeight: "29px",
      fontFamily: fontFamilyMontserrat,
      [`@media (max-width:${themeConstants.breakpoints.values.sm}px)`]: {
        fontSize: 21,
        lineHeight: "26px",
      },
    },
    h3: {
      fontSize: 21,
      lineHeight: "26px",
      fontFamily: fontFamilyMontserrat,
      fontWeight: 700,
      [`@media (max-width:${themeConstants.breakpoints.values.sm}px)`]: {
        fontSize: 18,
        lineHeight: "22px",
      },
    },
    h4: {
      fontSize: 18,
      lineHeight: "22px",
      fontFamily: fontFamilyMontserrat,
      [`@media (max-width:${themeConstants.breakpoints.values.sm}px)`]: {
        fontSize: 16,
        lineHeight: "20px",
      },
    },
    h5: {
      fontSize: 14,
      lineHeight: "21px",
      fontWeight: 600,
      fontFamily: fontFamilyMontserrat,
      [`@media (max-width:${themeConstants.breakpoints.values.sm}px)`]: {
        lineHeight: "18px",
      },
    },
    subtitle1: {
      fontFamily: fontFamilyHind,
      fontWeight: 600,
      lineHeight: "21px",
      fontSize: 14,
    },
    subtitle2: {
      fontFamily: fontFamilyHind,
    },
    body1: {
      fontFamily: `${fontFamilyHind} , 'Regular'`,
      fontWeight: 400,
      lineHeight: "22px",
      fontSize: 14,
      fontStyle: "normal",
      [`@media (max-width:${themeConstants.breakpoints.values.sm}px)`]: {
        lineHeight: "26px",
        fontSize: 16,
      },
    },
    body2: {
      fontFamily: `${fontFamilyHind} , 'Regular'`,
      lineHeight: "15px",
      fontSize: 12,
      [`@media (max-width:${themeConstants.breakpoints.values.sm}px)`]: {
        lineHeight: "23px",
      },
    },
    button: {
      fontFamily: `${fontFamilyHind} , 'Regular'`,
      lineHeight: "22px",
      fontSize: 14,
      [`@media (max-width:${themeConstants.breakpoints.values.sm}px)`]: {
        lineHeight: "26px",
        fontSize: 14,
      },
      textTransform: "initial",
    },
    caption: {
      fontFamily: fontFamilyHind,
    },
    overline: {
      fontFamily: fontFamilyHind,
    },
  },
});
