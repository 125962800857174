export const categoryTabs = [
  {
    id: 1,
    label: "LES MENUS",
    nested: [
      {
        id: 1,
        subLabel: "MENU SASHIMI / SUSHI",
      },
      {
        id: 2,
        subLabel: "MENU SUSHI / MAKI",
      },
      {
        id: 4,
        subLabel: "MENU BROCHETTE SUSHI SASHIMI MAKI",
        comment: `Les 5 brochettes se composent de 1 brochette de boulettes de poulet, 1 brochette de cuisse de poulet, 1 brochette d'aile de poulet, 1 brochette de canards, 1 brochette de fromage enroulé de bœuf (avec possibilité de changer 2 brochettes au maximum)`,
      },
      {
        id: 3,
        subLabel: "MENU CHIRASHI DON",
        comment: `Servi avec du riz vinaigré dans un bol`,
      },
    ],
  },
  {
    id: 2,
    label: "À LA CARTE",
    nested: [
      {
        id: 9,
        subLabel: "SALADES",
      },
      {
        id: 6,
        subLabel: "ENTRÉES ET PLATS CHAUDS",
      },

      {
        id: 10,
        subLabel: "SUSHI",
        comment: `Servi par paire / Lamelle de poisson cru ou autres ingrédients posés sur une boulette de riz`,
      },

      {
        id: 12,
        subLabel: "SASHIMI",
      },
      {
        id: 7,
        subLabel: "MAKI",
        comment: `Riz farci enroulé d'algue servi par 6`,
      },
      {
        id: 8,
        subLabel: "MAKI MODERNE",
      },
      {
        id: 11,
        subLabel: "TEMAKI",
        comment: `Servi par paire / Cornet d'algue garni`,
      },
      {
        id: 5,
        subLabel: "BROCHETTES",
        comment: `Servi par paire`,
      },
    ],
  },
  {
    id: 13,
    label: "DESSERTS",
  },
  {
    id: 4,
    label: "BOISSONS",
    nested: [
      {
        id: 14,
        subLabel: "SOFTS",
      },
      {
        id: 15,
        subLabel: "BIÈRES",
        comment: `L'abus d'alcool est dangereux pour la santé, à consommer avec modération !`,
      },
    ],
  },
];
