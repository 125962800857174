import React from "react";

import { Box, Container, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";

import UpdateInfosMessage from "../UpdateInfosMessage";
import UpdateOpeningHours from "../UpdateOpeningHours";
import UpdatePdfFile from "../UpdatePdfFile";
import useStyles from "./useStyles";

export default function UpdateInfosPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <MetaTags>
        <title>{t("metas.updateInfosPageTitle")}</title>
        <meta name="title" content={t("metas.updateInfosPageTitle")} />
        <meta
          name="description"
          content={t("metas.updateInfosPageDescription")}
        />
      </MetaTags>

      <Box className={classes.content}>
        <Container maxWidth="xl" sx={{ margin: "Opx auto 40px auto" }}>
          <Typography variant="h3" component="h3" sx={{ marginBottom: "20px" }}>
            {t("contactpage.messageTitle")}
          </Typography>
          <UpdateInfosMessage />

          <Divider variant="middle" style={{ marginBottom: 40 }} />

          <Typography variant="h3" component="h3" sx={{ marginBottom: "20px" }}>
            {t("contactpage.openingHoursTitle")}
          </Typography>
          <UpdateOpeningHours />

          <Divider variant="middle" style={{ marginBottom: 40 }} />

          <Typography variant="h3" component="h3" sx={{ marginBottom: "20px" }}>
            {t("contactpage.uploadPdfTitle")}
          </Typography>
          <UpdatePdfFile />

          <Divider variant="middle" style={{ marginBottom: 40 }} />
        </Container>
      </Box>
    </>
  );
}
