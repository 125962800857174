import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  separator: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: 268,
    width: 180,
  },
  logoMobile: {
    maxWidth: 150,
    width: 180,
    margin: "0 auto",
    padding: "20px 0px",
    position: "relative",
    left: "-16px",
  },
  logoLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  drawer: {
    width: 220,
  },
  themeSwitcherMobile: {
    marginTop: "auto",
    paddingLeft: 16,
    paddingRight: 16,
  },
});
export default useStyles;
