import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  content: {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    padding: "60px 20px",
  },
});
export default useStyles;
