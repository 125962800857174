import React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormHelperText,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { equals } from "ramda";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useNavigate, useParams } from "react-router-dom";

import SubMenuBeverage from "../../../designSystem/organism/SubMenuBeverage";
import { colors } from "../../../designSystem/template/theme/colors";
import {
  useBeverage,
  useBeverageDelete,
  useBeveragePatch,
} from "../../../hooks";
import useStyles from "./useStyles";

export default function Beverage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const params = useParams();

  const navigate = useNavigate();

  const { isLoading, data: fetchedBeverage, refetch } = useBeverage(params?.id);
  const beverage = fetchedBeverage?.[0];
  const beverageId = beverage?.id;

  const [beverageInfos, setBeverageInfos] = React.useState(beverage);
  const [validationErrors, setValidationErrors] = React.useState(beverage);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteSecurity, setDeleteSecurity] = React.useState(null);

  const patchBeverage = useBeveragePatch();
  const deleteBeverage = useBeverageDelete();

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  React.useEffect(() => {
    setBeverageInfos(beverage);
  }, [beverage]);

  React.useEffect(() => {
    if (patchBeverage.data) refetch();
  }, [patchBeverage.data, refetch]);

  React.useEffect(() => {
    if (deleteBeverage.data) navigate("/update-menu");
  }, [deleteBeverage.data, navigate]);

  React.useEffect(() => {
    let validationErrors = {};
    if (beverageInfos) {
      const hasNameError =
        (!beverageInfos.name || beverageInfos.name.startsWith(" ")) ?? true;
      const hasOtherPriceError = isNaN(beverageInfos.other) ?? true;
      const hasTwentyThreePriceError =
        isNaN(beverageInfos.twenty_three) ?? true;
      const hasTwentyFivePriceError = isNaN(beverageInfos.twenty_five) ?? true;
      const hasThirtyThreePriceError =
        isNaN(beverageInfos.thirty_three) ?? true;
      const hasFiftyPriceError = isNaN(beverageInfos.fifty) ?? true;
      const hasSeventyFivePriceError =
        isNaN(beverageInfos.seventy_five) ?? true;
      const hasOneHundredPriceError = isNaN(beverageInfos.one_hundred) ?? true;

      validationErrors = {
        ...validationErrors,
        name: hasNameError,
        other: hasOtherPriceError,
        twenty_three: hasTwentyThreePriceError,
        twenty_five: hasTwentyFivePriceError,
        thirty_three: hasThirtyThreePriceError,
        fifty: hasFiftyPriceError,
        seventy_five: hasSeventyFivePriceError,
        one_hundred: hasOneHundredPriceError,
      };
    }
    setValidationErrors(validationErrors);
  }, [beverageInfos]);

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <MetaTags>
        <title>{t("metas.updateMenuPageTitle")}</title>
        <meta name="title" content={t("metas.updateMenuPageTitle")} />
        <meta
          name="description"
          content={t("metas.updateMenuPageDescription")}
        />
      </MetaTags>

      <Box className={classes.content}>
        <Container maxWidth="xl" sx={{ margin: "Opx auto 40px auto" }}>
          <Button
            variant="outlined"
            onClick={() => navigate("/update-menu")}
            sx={{ marginTop: "30px", marginBottom: "40px" }}
          >
            Retour
          </Button>

          <Divider variant="middle" />
          <Button
            type="button"
            color="error"
            variant="contained"
            onClick={handleOpen}
            sx={{ margin: "30px 30px 30px 30px" }}
          >
            SUPPRIMER LA BOISSON DU MENU...
          </Button>
          <Divider variant="middle" />

          <Container
            maxWidth="lg"
            sx={{
              paddingTop: "30px",
              paddingBottom: "40px",
              textAlign: "left",
            }}
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6} xl={4}>
                <Box>
                  <Typography
                    variant="h2"
                    component="h2"
                    sx={{ marginBottom: "10px" }}
                  >
                    Actuel :
                  </Typography>
                  <SubMenuBeverage beverage={beverage} />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} xl={4}>
                {beverageInfos && (
                  <Box>
                    <Typography
                      variant="h2"
                      component="h2"
                      sx={{ marginBottom: "10px", fontWeight: "bold" }}
                    >
                      Nouveau :
                    </Typography>
                    <SubMenuBeverage beverage={beverageInfos} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Container>

          <Divider />
          <Container maxWidth="lg" sx={{ marginTop: "40px" }}>
            {beverageInfos && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  error={!!validationErrors.name}
                  placeholder="Nom"
                  label="Nom du plat"
                  type="text"
                  variant="outlined"
                  value={beverageInfos.name}
                  onChange={(e) => {
                    setBeverageInfos({
                      ...beverageInfos,
                      name: e.target.value.toUpperCase(),
                    });
                  }}
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                {!!validationErrors.name && (
                  <FormHelperText id="name-error" sx={{ color: "red" }}>
                    Il manque le nom de la boisson
                  </FormHelperText>
                )}

                <TextField
                  placeholder="Comment"
                  label="Comment"
                  type="text"
                  variant="outlined"
                  value={beverageInfos.comment}
                  onChange={(e) =>
                    setBeverageInfos({
                      ...beverageInfos,
                      comment: e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />

                <TextField
                  placeholder="Description"
                  label="Description"
                  type="text"
                  variant="outlined"
                  value={beverageInfos.description}
                  multiline
                  rows={4}
                  onChange={(e) =>
                    setBeverageInfos({
                      ...beverageInfos,
                      description:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <TextField
                  error={!!validationErrors.other}
                  placeholder="Autre quantité"
                  label="Autre quantité : préciser en commentaire la quantité"
                  type="text"
                  variant="outlined"
                  value={beverageInfos.other}
                  onChange={(e) =>
                    setBeverageInfos({
                      ...beverageInfos,
                      other: e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />

                <TextField
                  error={!!validationErrors.twenty_three}
                  placeholder="23 cl, ex : 15.90"
                  label="23 cl"
                  type="text"
                  variant="outlined"
                  value={beverageInfos.twenty_three}
                  onChange={(e) =>
                    setBeverageInfos({
                      ...beverageInfos,
                      twenty_three:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <TextField
                  error={!!validationErrors.twenty_five}
                  placeholder="25 cl, ex : 15.90"
                  label="25 cl"
                  type="text"
                  variant="outlined"
                  value={beverageInfos.twenty_five}
                  onChange={(e) =>
                    setBeverageInfos({
                      ...beverageInfos,
                      twenty_five:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <TextField
                  error={!!validationErrors.thirty_three}
                  placeholder="33 cl, ex : 15.90"
                  label="33 cl"
                  type="text"
                  variant="outlined"
                  value={beverageInfos.thirty_three}
                  onChange={(e) =>
                    setBeverageInfos({
                      ...beverageInfos,
                      thirty_three:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <TextField
                  error={!!validationErrors.fifty}
                  placeholder="50 cl, ex : 15.90"
                  label="50 cl"
                  type="text"
                  variant="outlined"
                  value={beverageInfos.fifty}
                  onChange={(e) =>
                    setBeverageInfos({
                      ...beverageInfos,
                      fifty: e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />

                <TextField
                  error={!!validationErrors.seventy_five}
                  placeholder="75 cl, ex : 15.90"
                  label="75 cl"
                  type="text"
                  variant="outlined"
                  value={beverageInfos.seventy_five}
                  onChange={(e) =>
                    setBeverageInfos({
                      ...beverageInfos,
                      seventy_five:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />

                <TextField
                  error={!!validationErrors.one_hundred}
                  placeholder="1 litre, ex : 15.90"
                  label="1 litre"
                  type="text"
                  variant="outlined"
                  value={beverageInfos.one_hundred}
                  onChange={(e) =>
                    setBeverageInfos({
                      ...beverageInfos,
                      one_hundred:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />

                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    patchBeverage.mutate({
                      beverage,
                      beverageInfos,
                      beverageId,
                    });
                  }}
                  sx={{ color: "black" }}
                  disabled={
                    Object.values(validationErrors).some(
                      (val) => val === true
                    ) || equals(beverageInfos, beverage)
                  }
                >
                  Modifier le plat
                </Button>
              </Box>
            )}
          </Container>
        </Container>
        <Modal open={openModal} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2">
              Vous êtes sur le point de supprimer la boisson {beverage.name}
            </Typography>
            <Typography sx={{ mt: 2, mb: 2 }}>
              Si vous le supprimez, il n'y aura pas de retour possible.
            </Typography>
            <Divider />
            <Typography sx={{ mt: 2 }}>
              Pour démarrer la suppression, taper :{" "}
            </Typography>
            <Typography sx={{ mb: 2, color: colors.SECONDARY }}>
              {beverage.name}
            </Typography>
            <TextField
              fullWidth
              placeholder="Sécurité"
              label="Sécurité"
              type="text"
              variant="outlined"
              value={deleteSecurity}
              onChange={(e) => setDeleteSecurity(e.target.value)}
              sx={{ marginTop: "10px", marginBottom: "10px" }}
            />

            <Divider />

            <Typography sx={{ mt: 2 }}>
              En appuyant sur le bouton suivant, la boisson va être supprimer.
            </Typography>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                deleteBeverage.mutate({
                  beverageId,
                });
              }}
              sx={{ color: "black" }}
              disabled={deleteSecurity !== beverage.name}
            >
              Supprimer la boisson définitivement
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
