import ContactPageIcon from "@mui/icons-material/ContactPage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useTranslation } from "react-i18next";

import RouteLink from "../../../designSystem/organism/RouteLink";

export default function PrivateRoutes() {
  const { t } = useTranslation();

  return (
    <>
      <RouteLink
        to="/dashboard"
        label={t("navbar.dashboard")}
        icon={<DashboardIcon color="secondary" />}
      />
      <RouteLink
        to="/update-menu"
        label={t("navbar.update")}
        icon={<MenuBookIcon color="secondary" />}
      />
      <RouteLink
        to="/update-infos"
        label={t("navbar.infos")}
        icon={<ContactPageIcon color="secondary" />}
      />
    </>
  );
}
