import React from "react";

import {
  Box,
  Button,
  Container,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";

import SubMenuDish from "../../../designSystem/organism/SubMenuDish";
import { useMenuPost } from "../../../hooks";
import useStyles from "./useStyles";

const vegetarianOptions = [
  {
    value: false,
    label: "NON",
  },
  {
    value: true,
    label: "OUI",
  },
];
export default function NewMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const classes = useStyles();

  const [categoryId] = useLocalStorage("sub-category-menu", null);
  const [categoryIdForDesserts] = useLocalStorage("category-menu", null);

  const [newMenu, setNewMenu] = React.useState({
    name: "",
    comment: null,
    description: "",
    price: null,
    is_vegetarian: false,
    allergy: null,
  });

  const [validationErrors, setValidationErrors] = React.useState({});
  const postNewMenu = useMenuPost();

  React.useEffect(() => {
    let validationErrors = {};
    if (newMenu) {
      const hasNameError =
        (!newMenu.name ||
          newMenu.name.startsWith(" ") ||
          newMenu.name.length === 0) ??
        true;
      const hasDescriptionError =
        (!newMenu.description ||
          newMenu.description.startsWith(" ") ||
          newMenu.description.length <= 1) ??
        true;
      const hasPriceError = (!newMenu.price || isNaN(newMenu.price)) ?? true;
      const hasAllergyError =
        ((!newMenu.allergy ||
          newMenu.allergy.startsWith(" ") ||
          newMenu.allergy.length === 0) &&
          newMenu.allergy !== null) ??
        true;

      validationErrors = {
        ...validationErrors,
        name: hasNameError,
        description: hasDescriptionError,
        price: hasPriceError,
        allergy: hasAllergyError,
      };
    }
    setValidationErrors(validationErrors);
  }, [newMenu]);

  React.useEffect(() => {
    if (postNewMenu.data) {
      navigate("/update-menu");
    }
  }, [navigate, postNewMenu.data]);

  return (
    <>
      <MetaTags>
        <title>{t("metas.updateMenuPageTitle")}</title>
        <meta name="title" content={t("metas.updateMenuPageTitle")} />
        <meta
          name="description"
          content={t("metas.updateMenuPageDescription")}
        />
      </MetaTags>

      <Box className={classes.content}>
        <Container maxWidth="xl" sx={{ margin: "Opx auto 40px auto" }}>
          <Button
            variant="outlined"
            onClick={() => navigate("/update-menu")}
            sx={{ marginTop: "30px", marginBottom: "40px" }}
          >
            Retour
          </Button>

          <Divider variant="middle" />

          <Typography
            variant="h2"
            component="h2"
            sx={{ marginBottom: "10px", fontWeight: "bold" }}
          >
            Nouveau plat dans {location.state.categoryLabel} /{" "}
            {location.state.subCategory}:
          </Typography>
          <Container
            maxWidth="lg"
            sx={{
              paddingTop: "30px",
              paddingBottom: "40px",
              textAlign: "left",
            }}
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6} xl={4}>
                {newMenu && (
                  <Box>
                    <SubMenuDish dish={newMenu} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Container>

          <Divider />
          <Container maxWidth="lg" sx={{ marginTop: "40px" }}>
            {newMenu && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  error={!!validationErrors.name}
                  placeholder="Nom"
                  label="Nom du plat"
                  type="text"
                  variant="outlined"
                  value={newMenu.name}
                  onChange={(e) => {
                    setNewMenu({
                      ...newMenu,
                      name: e.target.value.toUpperCase(),
                    });
                  }}
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                {!!validationErrors.name && (
                  <FormHelperText id="name-error" sx={{ color: "red" }}>
                    Il manque le nom du plat
                  </FormHelperText>
                )}
                <TextField
                  placeholder="Comment"
                  label="Comment"
                  type="text"
                  variant="outlined"
                  value={newMenu.comment}
                  onChange={(e) =>
                    setNewMenu({
                      ...newMenu,
                      comment: e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <TextField
                  error={!!validationErrors.description}
                  placeholder="Description"
                  label="Description"
                  type="text"
                  variant="outlined"
                  value={newMenu.description}
                  multiline
                  rows={4}
                  onChange={(e) =>
                    setNewMenu({ ...newMenu, description: e.target.value })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                {!!validationErrors.description && (
                  <FormHelperText id="name-error" sx={{ color: "red" }}>
                    Il faut ajouter une description du plat
                  </FormHelperText>
                )}
                <TextField
                  error={!!validationErrors.price}
                  placeholder="Prix avec un point (pas de virgule), ex : 15.90"
                  label="Prix avec un point (pas de virgule)"
                  type="text"
                  variant="outlined"
                  value={newMenu.price}
                  onChange={(e) =>
                    setNewMenu({ ...newMenu, price: e.target.value })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <TextField
                  placeholder="Végétarien : oui / non"
                  label="Végétarien"
                  select
                  value={newMenu?.is_vegetarian || false}
                  onChange={(e) =>
                    setNewMenu({
                      ...newMenu,
                      is_vegetarian: e.target.value === "true",
                    })
                  }
                  SelectProps={{
                    native: true,
                  }}
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  {vegetarianOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
                <TextField
                  error={!!validationErrors.allergy}
                  placeholder="Liste d'allergies"
                  label="Allergènes"
                  type="text"
                  variant="outlined"
                  value={newMenu.allergy}
                  onChange={(e) =>
                    setNewMenu({
                      ...newMenu,
                      allergy: e.target.value === "" ? null : e.target.value,
                    })
                  }
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    const id =
                      categoryIdForDesserts === 13
                        ? categoryIdForDesserts
                        : categoryId;
                    postNewMenu.mutate({
                      newMenu,
                      categoryId: id,
                    });
                  }}
                  sx={{ color: "black" }}
                  disabled={Object.values(validationErrors).some(
                    (val) => val === true
                  )}
                >
                  Ajouter le plat
                </Button>
              </Box>
            )}
          </Container>
        </Container>
      </Box>
    </>
  );
}
