import React from "react";

import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import Slider from "react-slick";

import MenuPdfLink from "../../../designSystem/molecule/MenuPdfLink";
import SubMenuBeverage from "../../../designSystem/organism/SubMenuBeverage";
import SubMenuDish from "../../../designSystem/organism/SubMenuDish";
import { colors } from "../../../designSystem/template/theme/colors";
import { useBeverages, useMenus } from "../../../hooks";
import { categoryTabs } from "./categoryTabs";
import useStyles from "./useStyles";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: true,
  lazyLoad: true,
  className: "center",
};
const menuPhoto01 = `${process.env.PUBLIC_URL}/static/assets/menu/1.png`;
const menuPhoto03 = `${process.env.PUBLIC_URL}/static/assets/menu/3.png`;
const menuPhoto04 = `${process.env.PUBLIC_URL}/static/assets/menu/4.png`;
const menuPhoto05 = `${process.env.PUBLIC_URL}/static/assets/menu/5.png`;

export default function MenuPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const [nestedValue, setNestedValue] = React.useState(0);

  const { isLoading, data: fetchedMenus } = useMenus();
  const { data: fetchedBeverages } = useBeverages();

  const selectedCategory = categoryTabs?.filter(
    (category) => category.id === value
  );

  const categoryLabel = categoryTabs.filter(
    (category) => category.id === value
  )[0]?.label;

  const subCategory = categoryTabs
    .filter((category) => category.id === value)[0]
    ?.nested?.filter((category) => category.id === nestedValue)[0];

  return (
    <>
      <MetaTags>
        <title>{t("metas.menuPageTitle")}</title>
        <meta name="title" content={t("metas.menuPageTitle")} />
        <meta name="description" content={t("metas.menuPageDescription")} />
      </MetaTags>
      {isLoading && <h1>...isLoading</h1>}
      <Box className={classes.content}>
        <Container
          maxWidth="md"
          sx={{ textAlign: "center", marginBottom: "30px" }}
        >
          <Typography component="h1" variant="h3" paragraph>
            {t("heading.menu").toUpperCase()}
          </Typography>
          <Typography component="p" variant="body1" paragraph>
            {t("menupage.availability")}
          </Typography>

          <MenuPdfLink />

          <Typography
            component="p"
            variant="subtitle1"
            paragraph
            className={classes.typeOfDishes}
          >
            {t("menupage.typeOfDishes")}
          </Typography>
        </Container>

        <Box className={classes.category}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            justifyContent="center"
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            sx={{ marginBottom: "20px" }}
          >
            <ButtonGroup variant={"outlined"} color="secondary">
              {categoryTabs?.map(({ label, id }) => (
                <Button
                  key={id}
                  onClick={() => {
                    setValue(id);
                    setNestedValue(0);
                  }}
                  style={{
                    color: value === id ? colors.BROWN.main : "inherit",
                    fontWeight: value === id ? 600 : "inherit",
                    fontSize: value === id ? 18 : "inherit",
                  }}
                >
                  {label}
                </Button>
              ))}
            </ButtonGroup>
          </Stack>
          {selectedCategory?.map(({ nested }) =>
            nested?.map((subCategory) => (
              <Button
                onClick={() => setNestedValue(subCategory.id)}
                key={subCategory.id}
                style={{
                  color:
                    nestedValue === subCategory.id
                      ? colors.BROWN.main
                      : "inherit",
                  fontWeight: nestedValue === subCategory.id ? 600 : "inherit",
                  fontSize: nestedValue === subCategory.id ? 18 : "inherit",
                }}
              >
                {subCategory.subLabel}
              </Button>
            ))
          )}
        </Box>

        <Container maxWidth="xl">
          <Container maxWidth="md" sx={{ margin: "0px auto 40px auto" }}>
            {categoryLabel && (
              <>
                <Typography variant="h2" color="text.primary" paragraph>
                  {categoryLabel} {subCategory && ` : ${subCategory.subLabel}`}
                </Typography>
                {subCategory?.comment && (
                  <Typography variant="h3" color="text.primary" gutterBottom>
                    {subCategory?.comment}
                  </Typography>
                )}
              </>
            )}
          </Container>
          <Grid container spacing={2}>
            {fetchedMenus
              ?.filter((item) => item.category_id === nestedValue)
              ?.map((item) => (
                <Grid item xs={12} md={6} xl={4}>
                  <SubMenuDish dish={item} />
                </Grid>
              ))}
            {value === categoryTabs?.[2]?.id &&
              nestedValue === 0 &&
              fetchedMenus
                ?.filter((item) => item.category_id === categoryTabs?.[2]?.id)
                ?.map((item) => (
                  <Grid item xs={12} md={6} xl={4}>
                    <SubMenuDish dish={item} />
                  </Grid>
                ))}
            {fetchedBeverages
              ?.filter((item) => item.category_id === nestedValue)
              ?.map((item) => (
                <Grid item xs={12} md={6} xl={4}>
                  <SubMenuBeverage beverage={item} />
                </Grid>
              ))}
          </Grid>
        </Container>
        <Container maxWidth="xl" sx={{ margin: "40px auto 10px auto" }}>
          <Slider {...settings} style={{ height: "auto" }}>
            <img
              src={menuPhoto01}
              alt="Petite assiette de dégustation de sashimi"
            />
            <img
              src={menuPhoto03}
              alt="Assiette d'assortiments de makis et sushis"
            />
            <img src={menuPhoto04} alt="Prise de vue sur un Chirashi don" />
            <img
              src={menuPhoto05}
              alt="Plat Fukushima, assortiments sashimis, sushis et 2 california makis"
            />
          </Slider>
        </Container>
      </Box>
    </>
  );
}
