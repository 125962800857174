import React from "react";

import { Box, Button, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useNavigate } from "react-router-dom";

import { constants } from "../../../constants";
import ThemeSwitcher from "../../../designSystem/molecule/ThemeSwitcher";
import { useAuth } from "../../../hooks";
import { supabase } from "../../../utils/supabaseClient";
import useStyles from "./useStyles";

const redirectUri = constants.authRedirectUri;

export default function AuthAdminPage() {
  const { checkSession } = useAuth();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    if (checkSession()) {
      navigate("/dashboard");
    }
  }, [checkSession, navigate]);

  const handleLogin = async (email) => {
    try {
      setSubmitted(false);
      setIsLoading(true);
      const { error } = await supabase.auth.signIn(
        { email },
        { redirectTo: redirectUri }
      );
      if (error) {
        setIsLoading(false);
        throw error;
      } else {
        setSubmitted(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.error_description || error.message);
    }
  };

  return (
    <>
      <MetaTags>
        <title>{t("metas.authenticationPageTitle")}</title>
        <meta name="title" content={t("metas.authenticationPageTitle")} />
        <meta
          name="description"
          content={t("metas.authenticationPageDescription")}
        />
      </MetaTags>
      <Box className={classes.mainContent}>
        {true && <ThemeSwitcher />}
        <Box className={classes.header}>
          <Typography
            variant="h1"
            component="h1"
            classes={{ root: classes.headline1 }}
          >
            Authentification
          </Typography>
          <Typography
            variant="h2"
            component="h2"
            classes={{ root: classes.headline1 }}
          >
            Connectez-vous avec le magic link en entrant votre email.
          </Typography>
        </Box>
        <Box className={classes.form}>
          <TextField
            autoFocus
            required
            fullWidth
            label="Email"
            id="email"
            type="email"
            variant="outlined"
            color="defaultColor"
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              className: classes.textinput,
            }}
          />
          <Button
            type="button"
            color="defaultColor"
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              handleLogin(email);
            }}
            disabled={isLoading}
          >
            {isLoading ? "Chargement..." : "Envoyer le magic link"}
          </Button>
        </Box>
        {!isLoading && submitted && (
          <Typography
            variant="h1"
            component="h1"
            classes={{ root: classes.successMessage }}
          >
            Un email vous a été envoyé, vérifiez vos emails pour vous connecter.
          </Typography>
        )}
      </Box>
    </>
  );
}
