import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  menuPdfContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "20px 0px",
  },
  leftFlower: {
    marginLeft: "auto",
    marginRight: 20,
  },
  rightFlower: {
    marginRight: "auto",
    marginLeft: 20,
  },
});
export default useStyles;
