import React from "react";

import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar,
} from "@mui/material";
import { node, string } from "prop-types";
import { Link } from "react-router-dom";

import { useMedia } from "../../../hooks";
import Address from "../../molecule/Address";
// import LanguageFlags from "../../molecule/LanguageFlags";
import Routes from "../../molecule/Routes";
import ThemeSwitcher from "../../molecule/ThemeSwitcher";
import Footer from "../Footer";
import useStyles from "./useStyles";

export default function PublicHeader({ logoUri, logoLabel }) {
  const classes = useStyles();

  const { DesktopAndAbove, TabletAndBelow } = useMedia();

  const [status, setStatus] = React.useState(false);

  const handleDrawerOpen = () => {
    setStatus(!status);
  };

  return (
    <>
      <TabletAndBelow>
        <AppBar position="fixed" color="defaultColor">
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Drawer
          open={status}
          onClose={handleDrawerOpen}
          anchor="left"
          classes={{ paper: classes.drawer }}
        >
          <Link to="/" className={classes.logoLink}>
            <img
              src={logoUri}
              alt={logoLabel}
              title={logoLabel}
              className={classes.logoMobile}
            />
          </Link>

          <Divider light />
          <List>
            <Routes />
          </List>

          <List sx={{ marginTop: "auto", textAlign: "center" }}>
            <Divider light />
            <Address />
            <Divider light />
            <Box style={{ display: "flex", justifyContent: "center" }}>
              {/* <LanguageFlags /> */}
            </Box>
            {true && <ThemeSwitcher className={classes.themeSwitcherMobile} />}
          </List>
          <Footer />
        </Drawer>
      </TabletAndBelow>

      <DesktopAndAbove>
        <AppBar position="fixed" elevation={1} color="defaultColor">
          <Container maxWidth="xl">
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <Link to="/">
                <img
                  src={logoUri}
                  alt={logoLabel}
                  title={logoLabel}
                  className={classes.logo}
                />
              </Link>
              <Address />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    aligntItems: "center",
                  }}
                >
                  {/* <LanguageFlags /> */}
                  {true && (
                    <ThemeSwitcher className={classes.themeSwitcherMobile} />
                  )}
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "66px",
                  }}
                >
                  <Routes />
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Toolbar
          style={{
            minHeight: "114px",
          }}
        />
      </DesktopAndAbove>
    </>
  );
}

PublicHeader.propTypes = {
  children: node,
  logoUri: string,
  logoLabel: string,
  right: node,
};
