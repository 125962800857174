import { CardActionArea } from "@mui/material";
import { bool, func, node } from "prop-types";

export default function MenuCardWrapper({
  isCardActionActive,
  children,
  action,
  ...options
}) {
  if (isCardActionActive) {
    return (
      <CardActionArea onClick={action} {...options}>
        {children}
      </CardActionArea>
    );
  }
  return children;
}

MenuCardWrapper.propTypes = {
  isCardActionActive: bool,
  children: node,
  action: func,
};
