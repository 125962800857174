import { useMutation } from "react-query";

import { supabase } from "../../utils/supabaseClient";

const patchMenuById = async ({ menu, dishInfos, menuId }) => {
  try {
    const { data, error } = await supabase
      .from("menu_item")
      .update({
        name: dishInfos.name !== "" ? dishInfos.name : menu.name,
        comment: dishInfos.comment !== "" ? dishInfos.comment : menu.comment,
        description:
          dishInfos.description !== ""
            ? dishInfos.description
            : menu.description,
        price: dishInfos.price !== "" ? dishInfos.price : menu.price,
        is_vegetarian: dishInfos.is_vegetarian,
        allergy: dishInfos.allergy !== "" ? dishInfos.allergy : menu.allergy,
        updated_at: new Date().toISOString().toLocaleString("fr-FR"),
      })
      .match({ id: menuId });

    if (error) throw new Error(error.message);
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error.error_description || error.message);
  }
};

export default function useMenuPatch() {
  return useMutation((datas) => patchMenuById(datas), {
    onSuccess: (data) => {
      return data;
    },
  });
}
