import { CssBaseline } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { AuthContext, ThemeContext } from "../../contexts";
import PrivateTemplate from "../../designSystem/template/PrivateTemplate";
import PublicTemplate from "../../designSystem/template/PublicTemplate";
import AuthAdminPage from "../authentication/AuthAdminPage";
import ContactPage from "../contact/ContactPage";
import Dashboard from "../Dashboard";
import HomePage from "../home/HomePage";
import MenuPage from "../menu/MenuPage";
import UpdateInfosPage from "../updateInfos/UpdateInfosPage";
import Beverage from "../updateMenu/Beverage";
import Menu from "../updateMenu/Menu";
import NewBeverage from "../updateMenu/NewBeverage";
import NewMenu from "../updateMenu/NewMenu";
import UpdateMenuPage from "../updateMenu/UpdateMenuPage";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider>
        <ThemeContext.Provider>
          <BrowserRouter>
            <CssBaseline />
            <Routes>
              <Route path="/authadmin" element={<AuthAdminPage />} />
              <Route element={<PublicTemplate />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/menu" element={<MenuPage />} />
                <Route path="/contact" element={<ContactPage />} />
              </Route>
              <Route element={<PrivateTemplate />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/update-menu" element={<UpdateMenuPage />} />
                <Route path="/update-menu/menu/:id" element={<Menu />} />
                <Route path="/update-menu/menu/new" element={<NewMenu />} />
                <Route
                  path="/update-menu/beverage/:id"
                  element={<Beverage />}
                />
                <Route
                  path="/update-menu/beverage/new"
                  element={<NewBeverage />}
                />
                <Route path="/update-infos" element={<UpdateInfosPage />} />
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </ThemeContext.Provider>
      </AuthContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
