import { useQuery } from "react-query";

import { supabase } from "../../utils/supabaseClient";

const getMenus = async () => {
  const { data, error } = await supabase
    .from("menu_item")
    .select("*")
    .order("price", { ascending: true });

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Menus not found");
  }
  return data;
};

export default function useMenus() {
  return useQuery(["menus"], () => getMenus(), {
    // refetchOnMount: false
  });
}
