import { useMutation } from "react-query";

import { supabase } from "../../utils/supabaseClient";

const postMenu = async ({ newMenu, categoryId }) => {
  try {
    const { data, error } = await supabase.from("menu_item").insert({
      category_id: categoryId,
      name: newMenu.name,
      comment: newMenu.comment,
      description: newMenu.description,
      price: newMenu.price,
      is_vegetarian: newMenu.is_vegetarian,
      allergy: newMenu.allergy,
    });
    if (error) throw new Error(error.message);
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error.error_description || error.message);
  }
};

export default function useMenuPost() {
  return useMutation((datas) => postMenu(datas), {
    onSuccess: (data) => {
      return data;
    },
  });
}
