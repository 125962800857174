import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  copyright: {
    padding: "10px 0px",
  },
  mobileCopyright: {
    padding: "10px 15px 20px 15px",
  },
});
export default useStyles;
