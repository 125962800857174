import React, { useState } from "react";

import { node, shape, string, func } from "prop-types";

import { supabase } from "../../utils/supabaseClient";

const AuthContext = React.createContext();

function useContext() {
  return React.useContext(AuthContext);
}

const Provider = ({ children }) => {
  const [user, setUser] = useState(null);

  const checkSession = () => {
    const session = supabase.auth.session();
    return session ? true : false;
  };

  React.useEffect(() => {
    const session = supabase.auth.session();
    setUser(session?.user ?? null);

    const { data: listener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (event === "SIGNED_IN") {
          setUser(session?.user ?? null);
        }
        if (event === "SIGNED_OUT") {
          setUser(null);
        }
      }
    );

    return () => {
      listener?.unsubscribe();
    };
  }, []);

  const value = {
    user,
    checkSession,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

Provider.propTypes = {
  children: node,
  user: shape({
    id: string.isRequired,
    email: string.isRequired,
  }),
  checkSession: func,
};

const CustomAuthContext = {
  Provider,
  useContext,
};

export default CustomAuthContext;
