import { Box, Container, Divider, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";

import { colors } from "../../../designSystem/template/theme/colors";
import { useAnnouncement, useOpeningHours } from "../../../hooks";
import { MyMap } from "../MyMap";
import useStyles from "./useStyles";

export default function ContactPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  const center = { lat: 50.638272727616514, lng: 3.0590797290804607 };
  const zoom = 18.5;

  const { isLoading: isAnnouncementLoading, data: announcement } =
    useAnnouncement();
  const { isLoading: isOpeningHoursLoading, data: openingHours } =
    useOpeningHours();

  return (
    <>
      <MetaTags>
        <title>{t("metas.contactPageTitle")}</title>
        <meta name="title" content={t("metas.contactPageTitle")} />
        <meta name="description" content={t("metas.contactPageDescription")} />
      </MetaTags>

      <Box className={classes.content}>
        <Typography
          component="h1"
          variant="h3"
          paragraph
          sx={{ marginTop: "40px" }}
        >
          {t("heading.informations").toUpperCase()}
        </Typography>
        {!isAnnouncementLoading && announcement && (
          <Typography
            component="p"
            variant="h3"
            paragraph
            sx={{ whiteSpace: "pre-line", color: colors.RED.main }}
          >
            {announcement[0]?.info}
          </Typography>
        )}
        <Divider variant="middle" light sx={{ marginBottom: "20px" }} />
        <Typography component="h1" variant="h3" paragraph>
          {t("heading.contact").toUpperCase()}
        </Typography>
        <Typography component="h1" variant="h4" paragraph>
          {t("heading.address").toUpperCase()}
        </Typography>
        <Link
          href="//goo.gl/maps/D3ucujdt1tf3ZudDA"
          target="_blank"
          rel="noreferrer"
          variant="subtitle1"
          color="secondary"
        >
          {t("contactpage.address")}
        </Link>
        <Typography
          component="h1"
          variant="h4"
          paragraph
          sx={{ marginTop: "20px" }}
        >
          {t("heading.schedule").toUpperCase()}
        </Typography>
        {!isOpeningHoursLoading && openingHours && (
          <Typography
            component="p"
            variant="subtitle1"
            paragraph
            sx={{ whiteSpace: "pre-line" }}
          >
            {openingHours[0]?.info}
          </Typography>
        )}
        <Divider variant="middle" light sx={{ marginBottom: "20px" }} />
        <Typography component="h1" variant="h3" paragraph>
          {t("heading.reservation").toUpperCase()}
        </Typography>
        <Link
          href="tel:+33320120498"
          target="_blank"
          rel="noreferrer"
          variant="subtitle1"
          color="secondary"
        >
          +33(0)3.20.12.04.98
        </Link>
        <Divider
          variant="middle"
          light
          sx={{ marginTop: "20px", marginBottom: "20px" }}
        />

        <Typography component="h1" variant="h3" paragraph>
          {t("heading.comment").toUpperCase()}
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          className={classes.textLineBreak}
          paragraph
        >
          {t("contactpage.link")}
        </Typography>
        <Link
          href="mailto:contact@restaurantsakura.com"
          target="_blank"
          rel="noreferrer"
          variant="subtitle1"
          color="secondary"
        >
          contact@restaurantsakura.com
        </Link>
      </Box>
      <Container
        maxWidth="xl"
        disableGutters
        sx={{ margin: "Opx auto 0px auto" }}
      >
        <MyMap center={center} zoom={zoom} />
      </Container>
    </>
  );
}
