import { Link, Typography } from "@mui/material";

import { useMedia } from "../../../hooks";

export default function Address() {
  const { isDesktopAndAboveMediaQuery } = useMedia();
  return (
    <address
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 16,
        marginRight: "auto",
        marginLeft: isDesktopAndAboveMediaQuery ? "30px" : 0,
      }}
    >
      <Typography variant="h4" component="h4">
        Restaurant Sakura
      </Typography>
      <Link
        href="//goo.gl/maps/D3ucujdt1tf3ZudDA"
        target="_blank"
        rel="noreferrer"
        variant="subtitle1"
        color="secondary"
      >
        16 bis, rue Thiers
      </Link>
      <Typography variant="p" component="p">
        59000 Lille
      </Typography>
    </address>
  );
}
