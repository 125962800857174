import React from "react";

import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const GM_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const MyMap = ({ center, zoom, children }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GM_API_KEY,
  });

  const [, setGMap] = React.useState(null);

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      setGMap(map);
    },
    [center]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setGMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        margin: "Opx auto 40px auto",
        height: 500,
        width: "100%",
        flexGrow: "1",
      }}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker position={center} />
      {children}
    </GoogleMap>
  ) : (
    <></>
  );
};
