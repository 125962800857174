import React from "react";

import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { node, string } from "prop-types";
import { Link, useLocation } from "react-router-dom";

import useStyles from "./useStyles";

export default function RouteLink(props) {
  const classes = useStyles();
  const location = useLocation();

  const { icon, label, to = false } = props;
  const isSelected = to === location.pathname;
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem
      button
      component={renderLink}
      selected={isSelected}
      className={classes.navButton}
      disableRipple
      disableTouchRipple
    >
      {icon ? (
        <ListItemIcon label="icon" sx={{ minWidth: 0, marginRight: "10px" }}>
          {icon}
        </ListItemIcon>
      ) : null}
      <ListItemText
        primary={label}
        primaryTypographyProps={
          isSelected
            ? { className: classes.selectedText }
            : { className: classes.navLabel }
        }
      />
    </ListItem>
  );
}

RouteLink.propTypes = {
  icon: node,
  label: string,
  to: string,
};
