import React from "react";

import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";

import SubMenuBeverage from "../../../designSystem/organism/SubMenuBeverage";
import SubMenuDish from "../../../designSystem/organism/SubMenuDish";
import { colors } from "../../../designSystem/template/theme/colors";
import { useBeverages, useMenus } from "../../../hooks";
import { categoryTabs } from "../../menu/MenuPage/categoryTabs";
import useStyles from "./useStyles";

export default function UpdateMenuPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  const navigate = useNavigate();

  const [categoryMenu, setCategoryMenu] = useLocalStorage(
    "category-menu",
    null
  );
  const [subCategoryMenu, setSubCategoryMenu] = useLocalStorage(
    "sub-category-menu",
    null
  );

  const [value, setValue] = React.useState(categoryMenu || 0);
  const [nestedValue, setNestedValue] = React.useState(subCategoryMenu || 0);

  const { isLoading, data: fetchedMenus } = useMenus();
  const { data: fetchedBeverages } = useBeverages();

  const selectedCategory = categoryTabs?.filter(
    (category) => category.id === value
  );

  const categoryLabel = categoryTabs.filter(
    (category) => category.id === value
  )[0]?.label;

  const subCategory = categoryTabs
    .filter((category) => category.id === value)[0]
    ?.nested?.filter((category) => category.id === nestedValue)[0];

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <MetaTags>
        <title>{t("metas.updateMenuPageTitle")}</title>
        <meta name="title" content={t("metas.updateMenuPageTitle")} />
        <meta
          name="description"
          content={t("metas.updateMenuPageDescription")}
        />
      </MetaTags>

      <Box className={classes.content}>
        <Container maxWidth="xl" sx={{ margin: "Opx auto 40px auto" }}>
          <Typography variant="h3" component="h3" sx={{ marginBottom: "20px" }}>
            MODIFICATION MENU
          </Typography>
          <Divider variant="middle" />

          <Box className={classes.category}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent="center"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              sx={{ marginBottom: "20px" }}
            >
              <ButtonGroup variant={"outlined"} color="secondary">
                {categoryTabs?.map(({ label, id }) => (
                  <Button
                    key={id}
                    onClick={() => {
                      setValue(id);
                      setNestedValue(0);
                      setCategoryMenu(id);
                    }}
                    style={{
                      color: value === id ? colors.BROWN.main : "inherit",
                      fontWeight: value === id ? 600 : "inherit",
                      fontSize: value === id ? 18 : "inherit",
                    }}
                  >
                    {label}
                  </Button>
                ))}
              </ButtonGroup>
            </Stack>
            {selectedCategory?.map(({ nested }) =>
              nested?.map((subCategory) => (
                <Button
                  onClick={() => {
                    setNestedValue(subCategory.id);
                    setSubCategoryMenu(subCategory.id);
                  }}
                  key={subCategory.id}
                  style={{
                    color:
                      nestedValue === subCategory.id
                        ? colors.BROWN.main
                        : "inherit",
                    fontWeight:
                      nestedValue === subCategory.id ? 600 : "inherit",
                    fontSize: nestedValue === subCategory.id ? 18 : "inherit",
                  }}
                >
                  {subCategory.subLabel}
                </Button>
              ))
            )}
          </Box>

          <Container maxWidth="xl">
            <Container maxWidth="md" sx={{ margin: "0px auto 40px auto" }}>
              {categoryLabel && (
                <Typography variant="h2" color="text.primary" paragraph>
                  {categoryLabel} {subCategory && ` : ${subCategory.subLabel}`}
                </Typography>
              )}
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={(e) => {
                  if (categoryMenu === 4) {
                    navigate("/update-menu/beverage/new", {
                      state: {
                        categoryLabel,
                        subCategory: subCategory.subLabel,
                      },
                    });
                  } else if (categoryMenu === 13 || value === 13) {
                    navigate("/update-menu/menu/new", {
                      state: {
                        categoryLabel,
                      },
                    });
                  } else {
                    navigate("/update-menu/menu/new", {
                      state: {
                        categoryLabel,
                        subCategory: subCategory.subLabel,
                      },
                    });
                  }
                }}
                sx={{
                  color: "black",
                  marginTop: "20px",
                  marginBottom: "20px",
                  backgroundColor: colors.RED.main,
                }}
              >
                Ajout un nouveau plat dans {categoryLabel}{" "}
                {subCategory && ` : ${subCategory.subLabel}`}
              </Button>
            </Container>

            <Grid container spacing={2}>
              {fetchedMenus
                ?.filter((item) => item.category_id === nestedValue)
                ?.map((item) => (
                  <Grid item xs={12} md={6} xl={4}>
                    <SubMenuDish
                      dish={item}
                      isCardActionActive
                      action={() => {
                        navigate(`/update-menu/menu/${item.id}`);
                      }}
                    />
                  </Grid>
                ))}
              {value === categoryTabs?.[2]?.id &&
                !subCategory &&
                fetchedMenus
                  ?.filter((item) => item.category_id === categoryTabs?.[2]?.id)
                  ?.map((item) => (
                    <Grid item xs={12} md={6} xl={4}>
                      <SubMenuDish
                        dish={item}
                        isCardActionActive
                        action={() => {
                          navigate(`/update-menu/menu/${item.id}`);
                        }}
                      />
                    </Grid>
                  ))}
              {fetchedBeverages
                ?.filter((item) => item.category_id === nestedValue)
                ?.map((item) => (
                  <Grid item xs={12} md={6} xl={4}>
                    <SubMenuBeverage
                      beverage={item}
                      isCardActionActive
                      action={() =>
                        navigate(`/update-menu/beverage/${item.id}`)
                      }
                    />
                  </Grid>
                ))}
            </Grid>
          </Container>
        </Container>
      </Box>
    </>
  );
}
