import CallIcon from "@mui/icons-material/Call";
import SetMealIcon from "@mui/icons-material/SetMeal";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { useTranslation } from "react-i18next";

import RouteLink from "../../../designSystem/organism/RouteLink";

export default function Routes() {
  const { t } = useTranslation();

  return (
    <>
      <RouteLink
        to="/"
        label={t("navbar.home")}
        icon={<StorefrontIcon color="secondary" />}
      />
      <RouteLink
        to="/menu"
        label={t("navbar.menu")}
        icon={<SetMealIcon color="secondary" />}
      />
      <RouteLink
        to="/contact"
        label={t("navbar.contact")}
        icon={<CallIcon color="secondary" />}
      />
    </>
  );
}
