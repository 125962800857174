import { useMutation } from "react-query";

import { supabase } from "../../utils/supabaseClient";

const postBeverage = async ({ newBeverage, categoryId }) => {
  try {
    const { data, error } = await supabase.from("beverage_item").insert({
      category_id: categoryId,
      name: newBeverage.name,
      comment: newBeverage.comment,
      description: newBeverage.description,
      other: newBeverage.other,
      twenty_three: newBeverage.twenty_three,
      twenty_five: newBeverage.twenty_five,
      thirty_three: newBeverage.thirty_three,
      fifty: newBeverage.fifty,
      seventy_five: newBeverage.seventy_five,
      one_hundred: newBeverage.one_hundred,
    });
    if (error) throw new Error(error.message);
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error.error_description || error.message);
  }
};

export default function useBeveragePost() {
  return useMutation((datas) => postBeverage(datas), {
    onSuccess: (data) => {
      return data;
    },
  });
}
