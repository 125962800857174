import { useQuery } from "react-query";

import { supabase } from "../../utils/supabaseClient";

const getBeverages = async () => {
  const { data, error } = await supabase.from("beverage_item").select("*");

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Beverages not found");
  }
  return data;
};

export default function useBeverages() {
  return useQuery(["beverages"], () => getBeverages(), {
    // refetchOnMount: false,
  });
}
