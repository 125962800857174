import { useQuery } from "react-query";

import { supabase } from "../../utils/supabaseClient";

const getOpeningHours = async () => {
  const { data, error } = await supabase
    .from("contact")
    .select("*")
    .match({ id: 2 });

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Hours not found");
  }
  return data;
};

export default function useOpeningHours() {
  return useQuery(["openingHours"], () => getOpeningHours(), {});
}
