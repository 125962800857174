import { makeStyles } from "@mui/styles";

import { colors } from "../../../designSystem/template/theme/colors";

const useStyles = makeStyles({
  mainContent: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    height: 500,
  },
  header: {
    textAlign: "center",
  },
  headline1: {
    marginBottom: 30,
  },
  form: {
    flexDirection: "column",
    textAlign: "center",
    width: "90%",
    maxWidth: 600,
  },
  textinput: {
    backgroundColor: "defaultColor",
    marginBottom: 30,
  },
  successMessage: {
    textAlign: "center",
    width: "90%",
    maxWidth: 500,
    color: colors.FUNCTIONAL.success,
  },
});
export default useStyles;
