import { Box, Link } from "@mui/material";

import { ThemeContext } from "../../../contexts";
import { useMenuPdfFile } from "../../../hooks";
import useStyles from "./useStyles";

const sakuraFlowerRed = `${process.env.PUBLIC_URL}/static/assets/fleursakura-red.png`;
const sakuraFlowerWhite = `${process.env.PUBLIC_URL}/static/assets/fleursakura.png`;

export default function MenuPdfLink() {
  const { selectedTheme } = ThemeContext.useContext();
  const {
    palette: { mode },
  } = selectedTheme;
  const classes = useStyles();

  const { isLoading: isPdfLoading, data: pdfFile } = useMenuPdfFile();

  return (
    <Box className={classes.menuPdfContainer}>
      {!isPdfLoading && (
        <>
          <img
            src={mode === "light" ? sakuraFlowerRed : sakuraFlowerWhite}
            alt="Petite fleur de Sakura encadrant le lien Menu.pdf"
            className={classes.leftFlower}
          />
          <Link
            href={pdfFile?.publicURL}
            target="_blank"
            rel="noopener noreferrer"
            variant="h2"
            color="secondary"
          >
            Voir le menu pdf
          </Link>
          <img
            src={mode === "light" ? sakuraFlowerRed : sakuraFlowerWhite}
            alt="Petite fleur de Sakura encadrant le lien Menu.pdf"
            className={classes.rightFlower}
          />
        </>
      )}
    </Box>
  );
}
