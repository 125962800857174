import { amber, grey } from "@mui/material/colors";

import { colors } from "./colors";

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mod
          // primary: { main: "#e23222b7", light: amber[600], dark: amber[900], contrastText: amber },
          common: { white: colors.WHITE, black: colors.BLACK },
          primary: {
            main: amber[400],
            light: amber[600],
            dark: amber[900],
            contrastText: amber,
          },
          secondary: {
            main: colors.BLACK,
            light: colors.WHITE,
            dark: colors.WHITE,
            contrastText: colors.WHITE,
          },
          defaultColor: colors.WHITE,
          background: {
            default: colors.WHITE,
            paper: colors.WHITE,
          },
          divider: grey[900],
          text: {
            primary: grey[900],
            secondary: grey[800],
            disabled: "rgba(255,255,255,0.5)",
            icon: colors.BLACK,
          },
          action: {
            // active: grey[900],
            // hover: "rgba(255,255,255,0.08)",
            // hoverOpacity: 0.08,
            // selected: "rgba(255,255,255,0.16)",
            // selectedOpacity: 0.16,
            // disabled: "rgba(255,255,255,0.3)",
            // disabledBackground: "rgba(255,255,255,0.12)",
            // disabledOpacity: 0.38,
            // focus: "rgba(255,255,255,0.12)",
            // focusOpacity: 0.12,
            // activeOpacity: 0.24,
          },
        }
      : {
          // palette values for dark mode
          common: { white: colors.WHITE, black: colors.BLACK },
          primary: {
            main: colors.WHITE,
            light: colors.WHITE,
            dark: colors.WHITE,
            contrastText: colors.WHITE,
          },
          secondary: {
            main: colors.WHITE,
            light: colors.WHITE,
            dark: colors.WHITE,
            contrastText: colors.WHITE,
          },
          // defaultColor: colors.BLACK,
          defaultColor: colors.NEUTRAL.dark,
          divider: colors.WHITE,
          background: {
            default: grey[900],
            // paper: grey[800],
            paper: colors.NEUTRAL.dark,
          },
          text: {
            primary: colors.WHITE,
            secondary: "rgba(255,255,255,0.7)",
            disabled: "rgba(255,255,255,0.5)",
            icon: "rgba(255,255,255,0.5)",
          },
          action: {
            active: colors.WHITE,
            hover: "rgba(255,255,255,0.08)",
            hoverOpacity: 0.08,
            selected: "rgba(255,255,255,0.16)",
            selectedOpacity: 0.16,
            disabled: "rgba(255,255,255,0.3)",
            disabledBackground: "rgba(255,255,255,0.12)",
            disabledOpacity: 0.38,
            focus: "rgba(255,255,255,0.12)",
            focusOpacity: 0.12,
            activeOpacity: 0.24,
          },
        }),
  },
});
