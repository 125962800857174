import { node } from "prop-types";
import { Navigate, useLocation } from "react-router-dom";

import { AuthContext } from "../../contexts";

export default function RequireAuth({ children }) {
  const { user } = AuthContext.useContext();
  const location = useLocation();
  return !user ? (
    <Navigate to="/authadmin" state={{ from: location }} />
  ) : (
    children
  );
}

RequireAuth.propTypes = {
  children: node,
};
